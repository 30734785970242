<template>
  <transition
    appear
    name="fade-pricing"
    mode="out-in"
  >
    <div
      ref="fixContainer"
      class="pricing-container"
    >
      <!--<vue-perfect-scrollbar>-->

      <div class="pricing-container__left">
        <transition
          appear
          name="fade-start"
          mode="out-in"
        >
          <div class="pricing-user">
            Welcome, {{ pricingUser }}!
            <span> </span>
          </div>
        </transition>
        <transition
          appear
          name="fade-pricing-left-text"
          mode="out-in"
        >
          <div
            v-if="!isPrayerwall"
            class="pricing-text"
          >
            <p>This is a limited time offer, available</p>
            <p>to the first 1,000 churches only.</p>
            <p>Sign up today and lock your price forever.</p>
            {{ pricingObj.timezone }}
          </div>
        </transition>
        <transition
          appear
          name="fade-pricing-left"
          mode="out-in"
        >
          <div class="pricing-list">
            <!--            pricing-list-item_checked-free-->
            <div
              v-for="item in prayerWallModule"
              :key="item.id"
              class="pricing-list-item"
              :class="{
                'pricing-list-item_checked': item.toggleClass,
                'pricing-list-item_open': isPrayerwall || dropPricingItem === 'prayerWallModule'
              }"
              @click="isPrayerwall ? false : openDropPricingItem('prayerWallModule')"
            >
              <div class="pricing-list-item__content pricing-list-item__content_prayer">
                <div
                  class="pricing-list-item__checkbox"
                  @click.stop
                >
                  <checkbox
                    :value="isPrayerwall ? item.checked : showStraightOffModalSignUp && item.checked"
                    :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                    :checkboxClass="'checkbox_lg checkbox_white'"
                    @checkboxChange="closePricingItem(item.toggleClass, $event, 'prayerWallModule', item)"
                  />
                </div>
                <div class="pricing-list-item__price">
                  <div class="pricing-list-item__medal">
                    <img
                      src="./assets/images/medal.png"
                      alt="medal"
                    >
                  </div>
                  ${{ getItemPrice(item) }}/month
                </div>
                <div
                  v-if="!isPrayerwall"
                  class="pricing-list-item__arrow"
                >
                  <i class="icon-arrow"></i>
                </div>
              </div>
              <transition
                name="fade-block"
                appear
                mode="out-in"
              >
                <div
                  v-if="isPrayerwall || dropPricingItem === 'prayerWallModule'"
                  class="pricing-list-item-drop"
                  @click.stop
                >
                  <div class="pricing-list-item-drop__block">
                    <div class="pricing-list-item-info">
                      <div class="pricing-list-item-info__title">
                        Here's whats included in Advanced Plan:
                      </div>
                      <ul class="pricing-list-item-info__ul">
                        <li> Unlimited administrators and prayer team members</li>
                        <li> Approval power and a host of other settings and permissions for your team </li>
                        <li> Ability to integrate our interactive prayer wall directly onto your website </li>
                        <li> AMP technology feature, allowing prayer team members to moderate posts, pray, and comment directly from their email address (from mobile phone/tablet)</li>
                      </ul>
                      <div class="pricing-list-item-info__p">
                        And the best part? We'll help you embed our prayer wall widget onto your website at no
                        additional charge. <br>
                        But this is a limited- time offer and won't be available later on.
                      </div>
                      <div class="pricing-list-item-info__p">
                        Don't miss out on this opportunity to boost your impact and reach more people through the
                        power of prayer.
                      </div>
                      <div class="pricing-list-item-info__p">
                        To explore CRM integration and harness advanced features like custom domains, badges, email sequences, and multi-campus/multi-networks architecture, please reach out to our team at
                        <a href="tel:614.929-8088">614.929-8088</a> or schedule a meeting by clicking the button below.
                      </div>
                      <a
                        class="btn pricing-list-item-info__btn"
                        href="https://calendly.com/chadkellogg/prayer_platform_meeting"
                        target="_blank"
                      >
                        Schedule a Zoom Meeting
                      </a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <template v-if="!isPrayerwall">
              <div
                v-for="item in websiteModule"
                :key="item.id"
                class="pricing-list-item"
                :class="{
                  'pricing-list-item_checked': item.toggleClass,
                  'pricing-list-item_open': dropPricingItem === 'websiteDropOpen'
                }"
                @click="openDropPricingItem('websiteDropOpen')"
              >
                <div class="pricing-list-item__content">
                  <div
                    class="pricing-list-item__checkbox"
                    @click.stop
                  >
                    <checkbox
                      :value="item.checked"
                      :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                      :checkboxClass="'checkbox_lg checkbox_white'"
                      @checkboxChange="closePricingItem(item.toggleClass, $event, 'websiteDropOpen', item)"
                    />
                  </div>
                  <div class="pricing-list-item__price">
                    ${{ item.price * organizationCoefficient }}/month
                  </div>
                  <div class="pricing-list-item__arrow">
                    <i class="icon-arrow"></i>
                  </div>
                </div>
                <transition
                  name="fade-block"
                  appear
                  mode="out-in"
                >
                  <div
                    v-if="dropPricingItem === 'websiteDropOpen'"
                    class="pricing-list-item-drop"
                    @click.stop
                  >
                    <div class="pricing-list-item-drop__block">
                      <div class="pricing-list-item-info pricing-list-item-info_mr">
                        <div class="pricing-list-item-info__title">
                          Powerful all-in-one giving platform that includes
                        </div>
                        <ul class="pricing-list-item-info__ul">
                          <li
                            v-for="item in websiteModulesList"
                            :key="item.id"
                          >
                            {{ item.text }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="pricing-list-item-drop__block pricing-list-item-drop__block_indent">
                      <div class="pricing-list-item-info">
                        <ul class="pricing-list-item-info__ul">
                          <li
                            v-for="item in websiteModulesListRight"
                            :key="item.id"
                          >
                            {{ item.text }}
                          </li>
                        </ul>
                      </div>
                      <div class="pricing-list-item-info">
                        <div class="pricing-list-item-info__title">
                          Additional options
                        </div>
                        <div
                          v-for="item in additionalOptionsWebsite"
                          :key="item.id"
                          class="form-group"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.title"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="calcCustomData(item, $event)"
                          />
                          <template v-if="item.text">
                            <div
                              class="form-group-text"
                              v-html="item.text"
                            ></div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <div
                v-for="item in mobileAppModule"
                :key="item.id"
                class="pricing-list-item"
                :class="[{'pricing-list-item_checked': item.toggleClass}, {'pricing-list-item_open': dropPricingItem === 'mobileAppOpen'}]"
                @click="openDropPricingItem('mobileAppOpen')"
              >
                <div class="pricing-list-item__content">
                  <div
                    class="pricing-list-item__checkbox"
                    @click.stop
                  >
                    <checkbox
                      :value="item.checked"
                      :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                      :checkboxClass="'checkbox_lg checkbox_white'"
                      @checkboxChange="closePricingItem(item.toggleClass, $event, 'mobileAppOpen', item, 'mobileAppOpen')"
                    />
                  </div>
                  <div class="pricing-list-item__price">
                    ${{ mobilePrice * organizationCoefficient }}/month
                    <div class="pricing-list-item__price-text">
                      ${{ item.setupFee }} Setup Fee
                    </div>
                  </div>
                  <div class="pricing-list-item__arrow">
                    <i class="icon-arrow"></i>
                  </div>
                </div>
                <transition
                  name="fade-block"
                  appear
                  mode="out-in"
                >
                  <div
                    v-if="dropPricingItem === 'mobileAppOpen'"
                    class="pricing-list-item-drop"
                    @click.stop
                  >
                    <div class="pricing-list-item-drop__block">
                      <div class="pricing-list-item-info">
                        <div class="pricing-list-item-info__title">
                          Available modules:
                        </div>
                        <div class="pricing-list-item-info__text">
                          Select modules that you want
                        </div>
                        <div
                          v-for="item in selectAllModules"
                          :key="item.id"
                          class="form-group"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.text"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="chooseAllModules(item, $event)"
                          />
                        </div>
                        <div
                          v-for="item in mobileAppModulesList"
                          :key="item.id"
                          class="form-group form-group_offset"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.text"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="calcCustomData(item, $event)"
                          />
                        </div>
                      <!--                      <select name="" id="">-->
                      <!--                        <option value="1">1</option>-->
                      <!--                        <option value="2">2</option>-->
                      <!--                        <option value="3">3</option>-->
                      <!--                      </select>-->
                      </div>
                    </div>
                    <div class="pricing-list-item-drop__block pricing-list-item-drop__block_indent pricing-list-item-drop__block_mobile">
                      <div class="pricing-list-item-info">
                        <ul class="pricing-list-item-info__ul">
                          <li
                            v-for="item in mobileAppModulesListRight"
                            :key="item.id"
                          >
                            {{ item.text }}
                          </li>
                        </ul>
                        <div class="pricing-list-item-info__desc">
                          Because of initial upfront development cost we require 2 year contract for mobile app
                        </div>
                      </div>
                      <div class="pricing-list-item-info">
                        <div class="pricing-list-item-info__title">
                          Additional options
                        </div>
                        <div
                          v-for="item in additionalOptionsMobileApp"
                          :key="item.id"
                          class="form-group"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="calcCustomData(item, $event)"
                          />
                          <template v-if="item.text">
                            <div
                              class="form-group-text"
                              v-html="item.text"
                            ></div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <div
                v-for="item in tvAppModule"
                :key="item.id"
                class="pricing-list-item pricing-list-item_tv"
                :class="[{'pricing-list-item_checked': item.toggleClass}, {'pricing-list-item_open': dropPricingItem === 'tvAppDropOpen'}]"
                @click="openDropPricingItem('tvAppDropOpen')"
              >
                <div class="pricing-list-item__content">
                  <div
                    class="pricing-list-item__checkbox"
                    @click.stop
                  >
                    <checkbox
                      :value="item.checked"
                      :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                      :checkboxClass="'checkbox_lg checkbox_white'"
                      @checkboxChange="closePricingItem(item.toggleClass, $event, 'tvAppDropOpen', item, 'tvAppDropOpen')"
                    />
                    <div class="pricing-list-item__checkbox-text">
                      Apple TV + ROKU + Amazon Fire TV + Android TV
                    </div>
                  </div>
                  <div class="pricing-list-item__price">
                    ${{ item.price * organizationCoefficient }}/month
                    <div class="pricing-list-item__price-text">
                      ${{ item.setupFee }} Setup Fee
                    </div>
                  </div>
                  <div class="pricing-list-item__arrow">
                    <i class="icon-arrow"></i>
                  </div>
                </div>
                <transition
                  name="fade-block"
                  appear
                  mode="out-in"
                >
                  <div
                    v-if="dropPricingItem === 'tvAppDropOpen'"
                    class="pricing-list-item-drop"
                    @click.stop
                  >
                    <div class="pricing-list-item-drop__block ">
                      <div class="pricing-list-item-info">
                        <div class="pricing-list-item-info__title">
                          Your custom TV apps
                        </div>
                        <div class="pricing-list-item-info__text">
                          Select platforms that you want
                        </div>
                        <div
                          v-for="item in tvAppList"
                          :key="item.id"
                          class="form-group"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.title"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="calcCustomData(item, $event)"
                          />
                        </div>
                      </div>
                      <div class="pricing-list-item-info">
                        <div class="pricing-list-item-info__title">
                          Audio Podcast
                        </div>
                        <div
                          v-for="item in audioPodcastList"
                          :key="item.id"
                          class="form-group"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.title"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="calcCustomData(item, $event)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="pricing-list-item-drop__block pricing-list-item-drop__block_tv">
                      <div class="pricing-list-item-info">
                        <div class="pricing-list-item-info__title">
                          TV App available modules
                        </div>
                        <div class="pricing-list-item-info__text">
                          Select modules that you want
                        </div>
                        <div
                          v-for="item in tvAppModulesList"
                          :key="item.id"
                          class="form-group"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.title"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="calcCustomData(item, $event)"
                          />
                        </div>
                        <div class="pricing-list-item-info__desc">
                          Because of initial upfront development cost we require 2 year contract for TV app
                        </div>
                        <!--                      <button class="btn btn_auto btn_green">Why you need TV App?</button>-->
                        <a
                          href="/tv"
                          target="_blank"
                          class="btn btn_auto btn_green btn_inline"
                        >Why you need TV App?</a>
                      </div>
                    <!--                    <div class="pricing-list-item-info">-->
                    <!--                      <div class="pricing-list-item-info__title">-->
                    <!--                        Additional options-->
                    <!--                      </div>-->
                    <!--                      <div class="form-group" v-for="item in additionalOptions" :key="item.id">-->
                    <!--                        <checkbox :value="item.checked"-->
                    <!--                                  :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"-->
                    <!--                                  @checkboxChange="calcCustomData(item, $event)"-->
                    <!--                                  :checkboxValueClass = "'checkbox__value_bigger'"-->
                    <!--                                  :checkboxClass = "'checkbox_modal'"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    </div>
                  </div>
                </transition>
              </div>
              <div
                v-for="item in giveModule"
                :key="item.id"
                class="pricing-list-item"
                :class="[{'pricing-list-item_checked': item.toggleClass}, {'pricing-list-item_open': dropPricingItem === 'givingPlatformDropOpen'}]"
                @click="openDropPricingItem('givingPlatformDropOpen')"
              >
                <div class="pricing-list-item__content">
                  <div
                    class="pricing-list-item__checkbox"
                    @click.stop
                  >
                    <checkbox
                      :value="item.checked"
                      :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                      :checkboxClass="'checkbox_lg checkbox_white'"
                      @checkboxChange="closePricingItem(item.toggleClass, $event, 'givingPlatformCheckbox', item)"
                    />
                    <div class="pricing-list-item__checkbox-text">
                      Available in the USA and Africa
                    </div>
                  </div>
                  <div class="pricing-list-item__price">
                    ${{ item.price }}/month
                    <div class="pricing-list-item__price-text">
                      <!--                    Limited Time Offer. ${{ modulePrice }}/month value-->
                      Limited Time Offer. $ 25/month value. $300/year savings.
                    </div>
                  </div>
                  <div class="pricing-list-item__arrow">
                    <i class="icon-arrow"></i>
                  </div>
                </div>
                <transition
                  name="fade-block"
                  appear
                  mode="out-in"
                >
                  <div
                    v-if="dropPricingItem === 'givingPlatformDropOpen'"
                    class="pricing-list-item-drop"
                    @click.stop
                  >
                    <div class="pricing-list-item-drop__block">
                      <div class="pricing-list-item-info pricing-list-item-info_mr">
                        <div class="pricing-list-item-info__title">
                          Powerful all-in-one giving platform that includes
                        </div>
                        <ul class="pricing-list-item-info__ul">
                          <li
                            v-for="item in givingPlatformList"
                            :key="item.id"
                          >
                            {{ item.text }}
                          </li>
                        </ul>
                      <!--                      <button class="btn btn_auto btn_red">Learn more</button>-->
                      </div>
                    </div>
                    <div class="pricing-list-item-drop__block pricing-list-item-drop__block_indent">
                      <div class="pricing-list-item-info">
                        <ul class="pricing-list-item-info__ul pricing-list-item-info__ul_width">
                          <li
                            v-for="item in givingPlatformListRight"
                            :key="item.id"
                          >
                            {{ item.text }}
                          </li>
                        </ul>
                        <div class="btn-row">
                          <button
                            class="btn btn_auto btn_green"
                            @click.prevent="showModalPriceComparison = true"
                          >
                            Pricing & Savings
                          </button>
                        <!--                        <button class="btn btn_auto btn_red">Learn more</button>-->
                        </div>
                      </div>
                      <div
                        v-if="false"
                        class="pricing-list-item-info"
                      >
                        <div class="pricing-list-item-info__title">
                          Additional options
                        </div>
                        <div
                          v-for="item in additionalOptionsGiving"
                          :key="item.id"
                          class="form-group"
                        >
                          <checkbox
                            :value="item.checked"
                            :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                            :checkboxValueClass="'checkbox__value_bigger'"
                            :checkboxClass="'checkbox_modal'"
                            @checkboxChange="calcCustomData(item, $event)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </template>
          </div>
        </transition>
        <template v-if="!isPrayerwall">
          <transition
            appear
            name="fade-pricing-left"
            mode="out-in"
          >
            <div class="pricing-list">
              <div
                v-for="item in donorManagementSystem"
                :key="item.id"
                class="pricing-list-item"
                :class="{'pricing-list-item_checked': item.toggleClass}"
              >
                <div class="pricing-list-item__content">
                  <div class="pricing-list-item__checkbox">
                    <checkbox
                      :value="!showStraightOffModalSignUp && item.checked"
                      :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                      :checkboxClass="'checkbox_lg checkbox_white'"
                      @checkboxChange="calcCustomData(item, $event)"
                    />
                  </div>
                  <div class="pricing-list-item__price">
                    $0/month
                    <div class="pricing-list-item__price-text">
                      Limited Time Offer. $ {{ modulePrice }}/month value. ${{ modulePrice * 12 }}/year savings.
                    </div>
                  </div>
                  <div class="pricing-list-item__arrow"></div>
                </div>
              </div>
              <div
                v-for="item in smartTemplates"
                :key="item.id"
                class="pricing-list-item"
                :class="{'pricing-list-item_checked': item.toggleClass}"
              >
                <div class="pricing-list-item__content">
                  <div class="pricing-list-item__checkbox">
                    <checkbox
                      :value="!showStraightOffModalSignUp && item.checked"
                      :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                      :checkboxClass="'checkbox_lg checkbox_white'"
                      @checkboxChange="calcCustomData(item, $event)"
                    />
                    <div class="pricing-list-item__checkbox-text">
                      Smart Templates™ + 10,000+ images, graphics & icons
                    </div>
                  </div>
                  <div class="pricing-list-item__price">
                    $0/month
                    <div class="pricing-list-item__price-text">
                      Limited Time Offer. ${{ modulePrice }}/month value. ${{ modulePrice * 12 }}/year savings.
                    </div>
                  </div>
                  <div class="pricing-list-item__arrow"></div>
                </div>
              </div>
              <div
                v-for="item in forms"
                :key="item.id"
                class="pricing-list-item"
                :class="{'pricing-list-item_checked': item.toggleClass}"
              >
                <div class="pricing-list-item__content">
                  <div class="pricing-list-item__checkbox">
                    <checkbox
                      :value="!showStraightOffModalSignUp && item.checked"
                      :checkboxText="item.pay ? checkboxTextTitle(item.title) : item.title"
                      :checkboxClass="'checkbox_lg checkbox_white'"
                      @checkboxChange="calcCustomData(item, $event)"
                    />
                  </div>
                  <div class="pricing-list-item__price">
                    $0/month
                    <div class="pricing-list-item__price-text">
                      Limited Time Offer. ${{ modulePrice }}/month value. ${{ modulePrice * 12 }}/year savings.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>
      </div>
      <!--</vue-perfect-scrollbar>-->
      <transition
        appear
        name="fade-pricing-right"
      >
        <div
          ref="sidebar"
          class="pricing-container__right"
          :class="{'pricing-container__right_full': fullHeightSection}"
        >
          <VuePerfectScrollbar class="pricing-container__scroll">
            <div class="pricing-container__right-content">
              <div class="pricing-container__title">
                Do You Want to Pay Monthly <br>
                or Annually?
              </div>
              <div class="pricing-container-tabs">
                <div class="pricing-container-tabs-nav">
                  <div
                    v-for="(item, index) in tabsList"
                    v-if="item"
                    :key="item.id"
                    class="pricing-container-tabs-nav__item"
                    :class="{'pricing-container-tabs-nav__item_active': activeIndex === index}"
                    @click="activeIndex = index, activePeriod(item.textApi)"
                  >
                    <div
                      v-if="item.off"
                      class="pricing-container-tabs-nav__item-off"
                    >
                      <span>{{ item.off }}</span> Off
                    </div>
                    {{ item.title }}
                  </div>
                </div>
                <div class="pricing-container-tabs-content">
                  <components
                    :is="tabsList[activeIndex].tabsContent"
                    :customDesign="customDesign"
                    :isPrayerwall="isPrayerwall"
                  ></components>
                </div>
              </div>
              <div class="pricing-container-promo">
                <div class="pricing-container-promo__wrapper">
                  <div class="pricing-container-promo__text">
                    Promotion coupon
                  </div>
                  <div class="pricing-container-promo__container">
                    <div class="pricing-container-promo__input">
                      <input
                        v-model="promo"
                        type="text"
                        placeholder="Insert coupon"
                        name="promo"
                        :class="{'error': promoError}"
                        @keyup.enter="submitPromo($event)"
                      >
                      <!--                @keyup="submitPromo($event)"-->
                      <!--                <i :class="{'active': promo.length > 2}" @click="submitPromo($event)" class="icon-enter"></i>-->
                      <!--                <i :class="{'active': promo.length > 2}" @click="submitPromo($event)" class="icon-enter"></i>-->
                      <transition name="fade-msg">
                        <span
                          v-show="validator.errors.has('promo')"
                          class="help is-danger"
                        >
                          {{ validator.errors.first('promo') }}
                        </span>
                      </transition>
                    </div>
                    <button
                      class="pricing-btn"
                      :class="{'active': promo.length > 2}"
                      @click="submitPromo($event)"
                    >
                      Apply
                    </button>
                  </div>
                  <div class="pricing-container-promo__message-pre">
                    <div
                      v-if="showValid"
                      class="pricing-container-promo__message"
                      :class="{'pricing-container-promo__message_error': !errorPromo}"
                    >
                      <template v-if="errorPromo">
                        <i class="icon-check-modal"></i>
                        <span>Valid!</span>
                      </template>
                      <template v-else>
                        <i class="icon-delete"></i>
                        <span>No valid</span>
                        <!--<i class="icon-confirm"></i>-->
                      </template>
                    </div>
                  </div>
                  <div class="pricing-container-promo-btn">
                    <button
                      class="pricing-btn"
                      :disabled="payModule()"
                      :class="{'pricing-btn_disabled': payModule()}"
                      @click.prevent="showModalAdd()"
                    >
                      Get started
                    </button>
                  </div>
                </div>
              </div>
              <!--            <div class="generate-pdf">-->
              <!--              <div class="generate-pdf__icon">-->
              <!--                <i class="icon-pdf"></i>-->
              <!--              </div>-->
              <!--              <div class="generate-pdf__text">Generate PDF presentation for your team</div>-->
              <!--            </div>-->
              <div class="sending-data">
                <button
                  class="btn sending-data__btn"
                  @click="showModalScheadule = true"
                >
                  Schedule a phone call
                </button>
                <a
                  class="btn sending-data__btn"
                  href="mailto:sales@impactfactors.net"
                >
                  Email Your Questions
                </a>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </transition>
      <transition
        name="fade-block-wait"
        appear
        mode="out-in"
      >
        <modals-wait
          v-if="showModalWait"
          :showModalWait.sync="showModalWait"
          :mobileAppModuleVar.sync="mobileAppModule[0]"
          :tvAppModuleVar.sync="tvAppModule[0]"
          @mobileAppModule="closePricingItem"
        >
        </modals-wait>
      </transition>
      <transition
        name="fade-block-wait"
        appear
        mode="out-in"
      >
        <modals-wait-giving
          v-if="showModalWaitGiving"
          :giveModuleVar.sync="giveModule[0]"
          @mobileAppModule="closePricingItem"
        >
        </modals-wait-giving>
      </transition>
      <transition
        name="fade-block-wait"
        appear
        mode="out-in"
      >
        <modals-schedule
          v-if="showModalScheadule"
          :isPrayerwall="isPrayerwall"
        >
        </modals-schedule>
      </transition>
      <transition
        name="animate-unfolding"
        appear
        mode="out-in"
      >
        <div
          v-if="showModalPriceComparison"
          v-shortkey="['esc']"
          class="modals-price-comparison"
          @shortkey="showModalPriceComparison = false"
        >
          <price-comparison :showModalPriceComparison.sync="showModalPriceComparison" />
        </div>
      </transition>
      <form
        ref="form"
        action="https://welcome.impactfactors.net/w/index.php"
        method="post"
        style="display: none"
      >
        <input
          id="signup"
          type="hidden"
          name="signup"
          :value="signup"
        >
        <input
          id="org"
          type="hidden"
          name="org"
          :value="org"
        >
      </form>
    </div>
  </transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import checkbox from '@/components/checkbox';
import monthlyContent from '@/modules/monthlyContent';
import annuallyContent from '@/modules/annuallyContent';
import twoYearsContent from '@/modules/twoYearsContent';
import modalsGetStarted from '@/components/modals/modalsGetStarted';
import modalsWait from '@/components/modals/modalsWait';
import modalsWaitGiving from '@/components/modals/modalsWaitGiving';
import modalsSchedule from '@/components/modals/modalsSchedule';
import modalsAttendance from '@/components/modals/modalsAttendance';
import priceComparison from '@/components/priceComparison';
import { Validator } from 'vee-validate';
import pricingApi from '@/api/pricingApi';
import { COUNTRY_LIST } from '@/constants/country';
// import MobileDetect from 'mobile-detect'

export default {
  name: 'PricingPage',
  components: {
    checkbox,
    monthlyContent,
    annuallyContent,
    twoYearsContent,
    modalsGetStarted,
    modalsWait,
    modalsAttendance,
    VuePerfectScrollbar,
    priceComparison,
    modalsSchedule,
    modalsWaitGiving,
  },
  props: ['isPrayerwall'],
  data() {
    return {
      promoDefault: '',
      countryList: COUNTRY_LIST,
      itemPriceTv: 0,
      signup: 1,
      org: '',
      fullHeightSection: false,
      promoModule: false,
      showValid: false,
      validator: {},
      custom_design: '',
      marketing: '',
      rock: '',
      api: '',
      itunes_audio: '',
      itunes_video: '',
      showModalPriceComparison: false,
      showModalWait: false,
      givingPlatformCheckbox: false,
      activeIndex: 0,
      tvAppDropOpen: false,
      givingPlatformDropOpen: false,
      websiteDropOpen: false,
      mobileAppOpen: false,
      tabsList: [
        {
          id: 1,
          title: 'Monthly',
          off: false,
          tabsContent: 'monthlyContent',
          textApi: 'monthly',
        },
        {
          id: 2,
          title: 'Annually',
          off: '10%',
          tabsContent: 'annuallyContent',
          textApi: 'yearly',
        },
        !this.isPrayerwall && {
          id: 3,
          title: '2 Years',
          off: '20%',
          tabsContent: 'twoYearsContent',
          textApi: '2yearly',
        },
      ],
      givingPlatformList: [
        {
          id: 1,
          text: 'Main website giving page',
        },
        {
          id: 2,
          text: 'Giving tab for sermons experience',
        },
        // {
        //   id: 3,
        //   text: 'Virtual Terminal'
        // },
        {
          id: 4,
          text: 'Mobile giving (with the Mobile app purchase)',
        },
        {
          id: 5,
          text: 'Giving reports',
        },
        {
          id: 6,
          text: 'Powerful system to manage all transactions',
        },
        {
          id: 7,
          text: 'Designation funds',
        },
        {
          id: 8,
          text: 'Ability to import transactions',
        },
      ],
      givingPlatformListRight: [
        {
          id: 1,
          text: 'Your own account with EpicPay',
        },
        {
          id: 2,
          text: 'No setup or monthly fees',
        },
        {
          id: 3,
          text: 'Activated within few hours',
        },
        {
          id: 4,
          text: 'Daily deposits to your account directly',
        },
        {
          id: 5,
          text: '2.3% transaction fees in the USA, and 1.4% in Nigeria if website and/or mobile app purchased (i) ',
        },
        {
          id: 6,
          text: 'No contract',
        },
      ],
      websiteModulesList: [
        {
          id: 1,
          text: 'Sermons (media platform)',
        },
        {
          id: 2,
          text: 'Events',
        },
        {
          id: 3,
          text: 'News',
        },
        {
          id: 4,
          text: 'Blogs',
        },
        {
          id: 5,
          text: 'Devotions',
        },
        {
          id: 6,
          text: 'Reading Plan',
        },
        {
          id: 7,
          text: 'Ministries',
        },
        {
          id: 8,
          text: 'Staﬀ',
        },
        {
          id: 9,
          text: 'Photos',
        },
        {
          id: 10,
          text: 'Contact Us',
        },
        {
          id: 11,
          text: 'User Registration',
        },
        {
          id: 12,
          text: 'About Us',
        },
        {
          id: 13,
          text: 'Bible',
        },
        {
          id: 14,
          text: 'Registration & User Profile',
        },
      ],
      websiteModulesListRight: [
        {
          id: 1,
          text: 'Unlimited number of custom pages',
        },
        {
          id: 2,
          text: 'Published under your domain name',
        },
        {
          id: 3,
          text: 'Easy to use website editor',
        },
        {
          id: 4,
          text: 'New design updates privilege (limited time oﬀer)',
        },
        {
          id: 5,
          text: '50Gb media storage included for your account',
        },
        {
          id: 6,
          text: 'Additional 100Gb will cost $20/month',
        },
        {
          id: 7,
          text: 'Unlimited bandwidth',
        },
        {
          id: 8,
          text: 'Integrated with Google Analytics',
        },
        {
          id: 9,
          text: 'General stats dashboard',
        },
        {
          id: 10,
          text: 'Connected with CRM, Social, Mobile & TV',
        },
        {
          id: 11,
          text: 'App promo page (with the mobile app purchase)',
        },
        {
          id: 12,
          text: 'Cancel anytime',
        },
      ],
      mobileAppModulesListRight: [
        {
          id: 1,
          text: 'Custom App for Apple & Android',
        },
        {
          id: 2,
          text: ' Apple Developer Certiﬁcate required',
        },
        {
          id: 3,
          text: '50Gb media storage included (total for your account) ',
        },
        {
          id: 4,
          text: 'Additional 100Gb will cost $25/month',
        },
        {
          id: 5,
          text: 'Unlimited bandwidth ',
        },
        // {
        //   id: 6,
        //   text: 'Push Notiﬁcations'
        // },
        {
          id: 7,
          text: 'User Registration/Account',
        },
      ],
      dropPricingItem: false,
      customDesign: 0,
      modulePrice: 25,
      itemPrice: 5,
    };
  },
  computed: {
    showStraightOffModalSignUp: {
      get() {
        return this.$store.getters.showStraightOffModalSignUp;
      },
      set(data) {
        this.$store.commit('setShowStraightOffModalSignUp', data);
      },
    },
    prayerWallModule: {
      get() {
        return this.$store.getters.prayerWallModule;
      },
      set(data) {
        this.$store.commit('setPrayerWallModule', data);
      },
    },
    websiteModule: {
      get() {
        return this.$store.getters.websiteModule;
      },
      set(data) {
        this.$store.commit('setWebsiteModule', data);
      },
    },
    mobileAppModule: {
      get() {
        return this.$store.getters.mobileAppModule;
      },
      set(data) {
        this.$store.commit('setMobileAppModule', data);
      },
    },
    mobilePrice() {
      const [mobileModule = {}] = this.mobileAppModule;
      const [tvAppModule = {}] = this.tvAppModule;
      const [websiteModule = {}] = this.websiteModule;
      const modulSelected = tvAppModule.checked || websiteModule.checked;
      const { price } = mobileModule;
      return modulSelected ? price : price * 2;
    },
    tvAppModule: {
      get() {
        return this.$store.getters.tvAppModule;
      },
      set(data) {
        this.$store.commit('setTvAppModule', data);
      },
    },
    giveModule: {
      get() {
        return this.$store.getters.giveModule;
      },
      set(data) {
        this.$store.commit('setGiveModule', data);
      },
    },
    donorManagementSystem: {
      get() {
        return this.$store.getters.donorManagementSystem;
      },
      set(data) {
        this.$store.commit('setDonorManagementSystem', data);
      },
    },
    smartTemplates: {
      get() {
        return this.$store.getters.smartTemplates;
      },
      set(data) {
        this.$store.commit('setSmartTemplates', data);
      },
    },
    forms: {
      get() {
        return this.$store.getters.forms;
      },
      set(data) {
        this.$store.commit('setForms', data);
      },
    },
    additionalOptionsWebsite: {
      get() {
        return this.$store.getters.additionalOptionsWebsite;
      },
      set(data) {
        this.$store.commit('setAdditionalOptionsWebsite', data);
      },
    },
    promo: {
      get() {
        return this.promoDefault;
      },
      set(data) {
        this.promoDefault = data;
      },
    },
    countryActive: {
      get() {
        return this.$store.getters.countryActive;
      },
      set(data) {
        this.$store.commit('setCountryActive', data);
      },
    },
    tags: {
      get() {
        return this.$store.getters.tags;
      },
      set(data) {
        this.$store.commit('changeTags', data);
      },
    },
    timeZoneActive: {
      get() {
        return this.$store.getters.timeZoneActive;
      },
      set(data) {
        this.$store.commit('setTimeZoneActive', data);
      },
    },
    packageFirst: {
      get() {
        return this.$store.getters.packageFirst;
      },
      set(data) {
        this.$store.commit('setPackageFirst', data);
      },
    },
    validPromoCode: {
      get() {
        return this.$store.getters.validPromoCode;
      },
      set(data) {
        this.$store.commit('setValidPromoCode', data);
      },
    },
    errorPromo: {
      get() {
        return this.$store.getters.errorPromo;
      },
      set(data) {
        this.$store.commit('setErrorPromo', data);
      },
    },
    packageSecond: {
      get() {
        return this.$store.getters.packageSecond;
      },
      set(data) {
        this.$store.commit('setPackageSecond', data);
      },
    },
    packageThird: {
      get() {
        return this.$store.getters.packageThird;
      },
      set(data) {
        this.$store.commit('setPackageThird', data);
      },
    },
    showModalScheadule: {
      get() {
        return this.$store.getters.showModalScheadule;
      },
      set(data) {
        this.$store.commit('setShowModalScheadule', data);
      },
    },
    showModalWaitGiving: {
      get() {
        return this.$store.getters.showModalWaitGiving;
      },
      set(data) {
        this.$store.commit('setShowModalWaitGiving', data);
      },
    },
    additionalOptionsMobileApp: {
      get() {
        return this.$store.getters.additionalOptionsMobileAppDef;
      },
      set(data) {
        this.$store.commit('setAdditionalOptionsMobileAppDef', data);
      },
    },
    additionalOptions: {
      get() {
        return this.$store.getters.additionalOptionsDef;
      },
      set(data) {
        this.$store.commit('setAdditionalOptionsDef', data);
      },
    },
    additionalOptionsGiving: {
      get() {
        return this.$store.getters.additionalOptionsGivingDef;
      },
      set(data) {
        this.$store.commit('setAdditionalOptionsGivingDef', data);
      },
    },
    selectAllModules: {
      get() {
        return this.$store.getters.selectAllModules;
      },
      set(data) {
        this.$store.commit('setSelectAllModules', data);
      },
    },
    tvAppList: {
      get() {
        return this.$store.getters.tvAppList;
      },
      set(data) {
        this.$store.commit('setTvAppList', data);
      },
    },
    tvAppModulesList: {
      get() {
        return this.$store.getters.tvAppModulesList;
      },
      set(data) {
        this.$store.commit('setTvAppModulesList', data);
      },
    },
    audioPodcastList: {
      get() {
        return this.$store.getters.audioPodcastList;
      },
      set(data) {
        this.$store.commit('setAudioPodcastList', data);
      },
    },
    mobileAppModulesList: {
      get() {
        return this.$store.getters.mobileAppModulesList;
      },
      set(data) {
        this.$store.commit('setMobileAppModulesList', data);
      },
    },
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    organizationCoefficient() {
      const { attendance } = this.pricingObj;
      switch (attendance) {
      case 1: return 2;
      case 2: return 6;
      case 3: return 10;
      default: return 1;
      }
    },
    pricingUser() {
      return this.pricingObj.firstName;
    },
    promoError() {
      if (this.isPrayerwall) {
        return this.validator.errors.has('promo') && this.promo.length > 2;
      }
      return this.validator.errors.has('promo') && this.promo.length > 2
        && this.mobileAppModule[0].checked && this.tvAppModule[0].checked
        && this.websiteModule[0].checked && this.promoModule;
    },
  },
  beforeMount() {
    // if (this.pricingObj.firstName === '' ||
    //   this.pricingObj.lastName === '' ||
    //   this.pricingObj.email === '' ||
    //   this.pricingObj.organization === ''
    // ) {
    //   this.$router.push({name: 'index'})
    // }

    if (this.isPrayerwall) {
      this.donorManagementSystem.forEach((item) => {
        item.checked = false;
      });
      this.smartTemplates.forEach((item) => {
        item.checked = false;
      });

      this.forms.forEach((item) => {
        item.checked = false;
      });
      const [prayerWall] = this.prayerWallModule;
      // prayerWall.checked = true;
      this.closePricingItem(prayerWall.toggleClass, true, 'prayerWallModule', prayerWall);
      document.body.style.overflowY = 'visible';
    }

    if (this.showStraightOffModalSignUp) {
      // before: services: "{\"website\":[],\"additionals\":[\"cms\",\"templates\",\"forms\"],\"prayerWall\":[]}"
      // after: services: "{\"prayerWall\":[]}"
      this.pricingObj.services = {
        prayerWall: [],
      };
    }
    if (this.$route.name === 'pricingPage') {
      document.querySelector('body').classList.add('body_pricing');
    }
    this.$nextTick(() => {
      // this.pricingObj.services.website.push(this.websiteModule[0].key)
      // this.closePricingItem(this.websiteModule[0].toggleClass, true, '', this.websiteModule[0])
      if (this.packageFirst) {
        if (!this.pricingObj.services.give) {
          this.pricingObj.services.give = [];
        }
        this.closePricingItem(this.giveModule[0].toggleClass, true, '', this.giveModule[0]);
      }
      if (this.packageSecond) {
        if (!this.pricingObj.services.mobile) {
          this.pricingObj.services.mobile = [];
        }
        this.closePricingItem(this.mobileAppModule[0].toggleClass, true, '', this.mobileAppModule[0]);
      }
      if (this.packageThird) {
        if (!this.pricingObj.services.mobile) {
          this.pricingObj.services.mobile = [];
        }
        if (!this.pricingObj.services.tv) {
          this.pricingObj.services.tv = [];
        }
        this.closePricingItem(this.mobileAppModule[0].toggleClass, true, '', this.mobileAppModule[0]);
        this.closePricingItem(this.tvAppModule[0].toggleClass, true, '', this.tvAppModule[0]);
      }
      // console.log(this.pricingObj.services)
    });
    this.validator = Validator.create();
    const dict = {
      en: {
        custom: {
          promo: {
            // required: 'The Promo is empty.',
            // promo: 'The Promo is not valid.'
            required: ' ',
            promo: ' ',
          },
        },
      },
    };
    this.validator.localize(dict);
    Validator.extend('promo', () => {
      if (this.isPrayerwall) {
        const promo1 = 'trial12';
        const promo2 = 'ppunlim';
        return this.promo.toLocaleLowerCase() === promo1
          || this.promo.toLocaleLowerCase() === promo2;
      }
      const promo = 'vip';
      return this.promo.toLocaleLowerCase() === promo || this.promo.toLocaleLowerCase() === `${promo} `;
    });
    this.validator.attach(
      {
        name: 'promo',
        rules: { required: true, promo: true },
      },
    );
    this.fixContainer();
    window.addEventListener('resize', this.fixContainer);
    const { attendance } = this.pricingObj;
    console.log('attendance', attendance);
    switch (attendance) {
    case 0: {
      this.modulePrice = 5;
      break;
    }
    case 1: {
      this.modulePrice = 15;
      break;
    }
    case 2: {
      this.modulePrice = 25;
      break;
    }
    case 3: {
      this.modulePrice = 40;
    }
    }
    const to = this.$route;
    const nearestWithMeta = to.meta.metaTags;
    nearestWithMeta.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
    // API
    pricingApi.timezonesList().then((resp) => {
      this.$store.commit('setTimezoneList', resp.data.tz);
    }).catch((err) => {
      console.log(err);
    });
    pricingApi.checkGeoInfo().then((resp) => {
      if (resp) {
        this.countryActive = resp.data.country;
        // this.countryActive = 'US'
        if (resp.data.city !== null) {
          this.pricingObj.city = resp.data.city;
        }
        if (resp.data.state !== null) {
          this.pricingObj.state = resp.data.state;
        }
        if (resp.data.zip !== null) {
          this.pricingObj.zip = resp.data.zip;
        }
        // if (resp.data.timezone !== null && resp.data.country === 'US') {
        //   this.timeZoneActive = resp.data.timezone
        //   console.log('timeZone!!!!!!!!')
        // }
        if (resp.data.timezone !== null) {
          this.timeZoneActive = resp.data.timezone;
        }
        pricingApi.timezonesList(this.countryActive).then((response) => {
          this.$store.commit('setTimezoneList', response.data.tz);
        }).catch((err) => {
          console.log(err);
        });
      }
    }).catch((err) => {
      console.log(err);
      if (err) {
        this.countryActive = 'US';
        pricingApi.timezonesList(this.countryActive).then((resp) => {
          this.$store.commit('setTimezoneList', resp.data.tz);
        }).catch((err) => {
          console.log(err);
        });
      }
    });
  },
  beforeDestroy() {
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
    window.removeEventListener('resize', this.fixContainer);
    document.querySelector('body').classList.remove('body_pricing');
  },
  methods: {
    getItemPrice(item) {
      const { coefficient } = item;
      const { attendance } = this.pricingObj;
      const organizationCoefficient = coefficient ? coefficient[attendance] || 1 : this.organizationCoefficient;
      return item.price * organizationCoefficient;
    },
    subForm() {
      window.fbq('trackCustom', 'Sign Up Form Step 3 - Pricing');
      this.$router.push({ name: 'pricingStep1' });
    },
    // subForm: function () {
    //   document.getElementById('org').value = JSON.stringify(this.pricingObj)
    //   document.getElementById('signup').value = '1'
    //   console.log('SEND!!!')
    //   this.$refs.form.submit()
    // },
    // sendData: function () {
    //   console.log('SEND DATA ++++++++++++++++++')
    //   this.org = JSON.stringify(this.pricingObj)
    //   // this.signup
    //   pricingApi.sendPricing({
    //     org: this.org,
    //     signup: this.signup
    //     // org: this.pricingObj
    //   }, (response) => {
    //     console.log(response)
    //   }, (err) => {
    //     console.log(err)
    //   })
    // },
    showModalAdd() {
      if (this.prayerWallModule[0].checked && !this.mobileAppModule[0].checked && !this.tvAppModule[0].checked && !this.websiteModule[0].checked && !this.giveModule[0].checked) {
        this.showModalWaitGiving = false;
        this.showModalWait = false;
        this.subForm();
      } else if (this.mobileAppModule[0].checked && this.tvAppModule[0].checked && !this.giveModule[0].checked) {
        this.showModalWaitGiving = true;
        this.showModalWait = false;
        // this.sendData()
        // this.subForm()
      } else if (this.mobileAppModule[0].checked && this.tvAppModule[0].checked && this.websiteModule[0].checked && this.giveModule[0].checked) {
        this.showModalWaitGiving = false;
        this.showModalWait = false;
        // this.sendData()
        this.subForm();
      } else {
        this.showModalWait = true;
      }
    },
    payModule() {
      if (!this.prayerWallModule[0].checked && !this.mobileAppModule[0].checked && !this.tvAppModule[0].checked && !this.websiteModule[0].checked && !this.giveModule[0].checked) {
      // if (!this.mobileAppModule[0].checked && !this.tvAppModule[0].checked && !this.websiteModule[0].checked && !this.giveModule[0].checked) {
        return true;
      }
    },
    calcPricing(price, flag, item = {}) {
      const { coefficient } = item;
      const { attendance } = this.pricingObj;
      const organizationCoefficient = coefficient ? coefficient[attendance] || 1 : this.organizationCoefficient;

      if (flag) {
        this.pricingObj.amount += price * organizationCoefficient;
      } else {
        this.pricingObj.amount -= price * organizationCoefficient;
      }
      // console.log(this.pricingObj.amount)
    },
    calcPricingCustom(price, flag) {
      if (flag) {
        this.pricingObj.setup += price;
      } else {
        this.pricingObj.setup -= price;
      }
    },
    checkboxTextTitle(text) {
      return text.replace(new RegExp('%price%', 'g'), this.modulePrice);
    },
    submitPromo() {
      // if (!this.errorPromo && this.mobileAppModule[0].checked && this.tvAppModule[0].checked && this.websiteModule[0].checked && this.promoModule) {
      // if (this.errorPromo) {
      //   let index = this.tags.indexOf('VIP')
      //   if (index === -1) {
      //     this.tags.push('VIP')
      //   }
      // } else {
      //   let index = this.tags.indexOf('VIP')
      //   if (index !== -1) {
      //     this.tags.splice(index, 1)
      //   }
      // }
      this.$nextTick(() => {
        if ((this.promo.length > 2)) {
          this.showValid = true;
        } else {
          this.showValid = false;
          this.errorPromo = false;
        }
        this.validator.validateAll({
          promo: this.promo,
        }).then((result) => {
          this.pricingObj.promo = this.promo.toLowerCase();
          console.log('result', result);
          if (result) {
            this.errorPromo = true;
            // this.pricingObj.promo = 1
            if (!this.isPrayerwall) {
              const index = this.tags.indexOf('VIP');
              console.log(index);
              if (index === -1) {
                this.tags.push('VIP');
              }
            }
          } else {
            this.errorPromo = false;
            // this.pricingObj.promo = 0
            if (!this.isPrayerwall) {
              const index = this.tags.indexOf('VIP');
              console.log(index);
              if (index !== -1) {
                this.tags.splice(index, 1);
              }
            } else {
              this.pricingObj.promo = '';
            }
          }
          console.log(this.tags);
        });
      });
      this.showValid = false;
      this.errorPromo = false;
    },
    activePeriod(item) {
      this.pricingObj.period = item;
      // console.log(this.pricingObj)
    },
    chooseAllModules(item, e) {
      this.$set(item, 'checked', e);
      // console.log(e)
      if (e) {
        if (!this.pricingObj.services.mobile) {
          this.pricingObj.services.mobile = [];
        }
        if (!this.mobileAppModule[0].checked) {
          this.closePricingItem(this.mobileAppModule[0].toggleClass, e, '', this.mobileAppModule[0]);
        }
        for (let i = 0; i < this.mobileAppModulesList.length; i++) {
          this.mobileAppModulesList[i].checked = true;
          this.pricingObj.services.mobile.push(this.mobileAppModulesList[i].key);
        }
      } else {
        item.checked = e;
        for (let i = 0; i < this.mobileAppModulesList.length; i++) {
          this.mobileAppModulesList[i].checked = false;
          this.pricingObj.services.mobile.splice(this.pricingObj.services.mobile.indexOf(this.mobileAppModulesList[i].key), 1);
        }
      }
      // console.log(this.pricingObj.services)
    },
    calcCustomData(item, e) {
      this.$set(item, 'checked', e);
      let flag = '';
      // console.log(item)
      if (item.checked) {
        // if (item.key === 'itunes_audio' || item.key === 'itunes_video') {
        //   this.closePricingItem(this.tvAppModule[0].toggleClass, e, '', this.tvAppModule[0])
        // }
        if (item.additionals) {
          if (!this.pricingObj.services.additionals) {
            this.pricingObj.services.additionals = [];
          }
          if ((!this.mobileAppModule[0].checked) && (item.key === 'rock' || item.key === 'custom_design' || item.key === 'api' || item.key === 'cbb' || item.key === 'marketing')) {
            this.closePricingItem(this.mobileAppModule[0].toggleClass, e, '', this.mobileAppModule[0]);
          } else if ((!this.tvAppModule[0].checked) && (item.key === 'itunes_audio' || item.key === 'itunes_video')) {
            this.closePricingItem(this.tvAppModule[0].toggleClass, e, '', this.tvAppModule[0]);
          }
          this.pricingObj.services.additionals.push(item.key);
          if (item.pay) {
            flag = true;
            this.calcPricing(this.modulePrice, flag);
          }
          if (item.customPrice) {
            flag = true;
            this.calcPricingCustom(item.price, flag);
          }
          if (!item.toggleClass) {
            item.toggleClass = true;
          }
        } else if (item.prayerWallModule) {
          if (!this.pricingObj.services.prayerWall) {
            this.pricingObj.services.prayerWall = [];
          }
          if (!this.prayerWallModule[0].checked) {
            this.closePricingItem(this.prayerModule[0].toggleClass, e, '', this.prayerModule[0]);
          }
          this.pricingObj.services.give.push(item.key);
          if (item.pay) {
            flag = true;
            this.calcPricing(this.modulePrice, flag);
          }
        } else if (item.give) {
          if (!this.pricingObj.services.give) {
            this.pricingObj.services.give = [];
          }
          if (!this.giveModule[0].checked) {
            this.closePricingItem(this.giveModule[0].toggleClass, e, '', this.giveModule[0]);
          }
          this.pricingObj.services.give.push(item.key);
          if (item.pay) {
            flag = true;
            this.calcPricing(this.modulePrice, flag);
          }
        } else if (item.website) {
          if (!this.pricingObj.services.website) {
            this.pricingObj.services.website = [];
          }
          if (item.customPrice) {
            flag = true;
            this.calcPricingCustom(item.price, flag);
            if (!this.pricingObj.services.additionals) {
              this.pricingObj.services.additionals = [];
            }
            this.pricingObj.services.additionals.push(item.key);
          } else {
            this.pricingObj.services.website.push(item.key);
          }
          if (!this.websiteModule[0].checked) {
            this.closePricingItem(this.websiteModule[0].toggleClass, e, '', this.websiteModule[0]);
          }
        } else if (item.mobile) {
          if (!this.pricingObj.services.mobile) {
            this.pricingObj.services.mobile = [];
          }
          if (!this.mobileAppModule[0].checked) {
            this.closePricingItem(this.mobileAppModule[0].toggleClass, e, '', this.mobileAppModule[0]);
          }
          this.pricingObj.services.mobile.push(item.key);
        } else if (item.platforms) {
          if (!this.pricingObj.services.platforms) {
            this.pricingObj.services.platforms = [];
          }
          if (!this.tvAppModule[0].checked) {
            this.closePricingItem(this.tvAppModule[0].toggleClass, e, '', this.tvAppModule[0]);
          }
          this.pricingObj.services.platforms.push(item.key);
        } else if (item.tv) {
          if (!this.pricingObj.services.tv) {
            this.pricingObj.services.tv = [];
          }
          if (!this.tvAppModule[0].checked) {
            this.closePricingItem(this.tvAppModule[0].toggleClass, e, '', this.tvAppModule[0]);
          }
          this.pricingObj.services.tv.push(item.key);
        }
      } else if (item.additionals) {
        this.pricingObj.services.additionals.splice(this.pricingObj.services.additionals.indexOf(item.key), 1);
        if (item.pay) {
          flag = false;
          this.calcPricing(this.modulePrice, flag);
        }
        if (item.customPrice) {
          flag = false;
          console.log(item.customPrice, item);
          this.calcPricingCustom(item.price, flag);
        }
        if (item.toggleClass) {
          item.toggleClass = false;
        }
      } else if (item.give) {
        this.pricingObj.services.give.splice(this.pricingObj.services.give.indexOf(item.key), 1);
        // if (!this.pricingObj.services.give.length) {
        //   this.closePricingItem(this.giveModule[0].toggleClass, e, '', this.giveModule[0])
        // }
        if (item.pay) {
          flag = false;
          this.calcPricing(this.modulePrice, flag);
        }
      } else if (item.website) {
        if (item.customPrice) {
          flag = false;
          this.calcPricingCustom(item.price, flag);
          this.pricingObj.services.additionals.splice(this.pricingObj.services.additionals.indexOf(item.key), 1);
        } else {
          this.pricingObj.services.website.splice(this.pricingObj.services.website.indexOf(item.key), 1);
        }
      } else if (item.mobile) {
        this.pricingObj.services.mobile.splice(this.pricingObj.services.mobile.indexOf(item.key), 1);
      } else if (item.platforms) {
        this.pricingObj.services.platforms.splice(this.pricingObj.services.platforms.indexOf(item.key), 1);
      } else if (item.tv) {
        this.pricingObj.services.tv.splice(this.pricingObj.services.tv.indexOf(item.key), 1);
      }
    },
    closePricingItem(val, e, a, item, action) {
      console.log('closePricingItem');
      const [mobileAppModule] = this.mobileAppModule;
      if (!item.mobile && mobileAppModule.checked) {
        this.calcPricing(this.mobilePrice, false);
      }
      this.$set(item, 'checked', e);

      if (!item.mobile && mobileAppModule.checked) {
        this.calcPricing(this.mobilePrice, true);
      }
      this[val] = e;
      let flag = '';
      // console.log(this.pricingObj.services)
      if (e) {
        if (this.dropPricingItem === a) {
          this.dropPricingItem = false;
        }
      }
      // show mob app content
      if (this.dropPricingItem === action) {
        this.dropPricingItem = false;
      } else {
        this.dropPricingItem = action;
      }
      // show mob app content
      // if (this.mobileAppModule[0].checked && this.tvAppModule[0].checked) {
      //   this.showModalWait = false
      // }
      if (item.checked) {
        // if (this.tvAppModule[0].checked && !this.mobileAppModule[0].checked && !this.websiteModule[0].checked && this.pricingObj.attendance === 0) {
        //   this.itemPriceTv = 40
        // } else {
        //   this.itemPriceTv = 25
        // }
        if (this.mobileAppModule[0].checked && this.tvAppModule[0].checked && this.websiteModule[0].checked) {
          // let promo = 'vip'
          // return this.promo.toLocaleLowerCase() === promo || this.promo.toLocaleLowerCase() === promo + ' '
          this.promoModule = true;
          this.validPromoCode = true;
        } else {
          this.validPromoCode = false;
        }
        if (item.prayer) {
          if (!this.pricingObj.services.prayerWall) {
            this.pricingObj.services.prayerWall = [];
          }
          this.tags.push('Interactive Prayer Wall');
          // this.pricingObj.services.website.push(item.key)
          if (item.pay) {
            flag = true;
            this.calcPricing(item.price, flag, item);
          }
          if (!item.toggleClass) {
            item.toggleClass = true;
          }
        } else if (item.website) {
          if (!this.pricingObj.services.website) {
            this.pricingObj.services.website = [];
          }
          this.pricingObj.services.website.push('sermons', 'events', 'news', 'blog', 'devotional', 'reading_plan', 'ministries', 'staff', 'photos', 'followUs', 'aboutUs', 'bible', 'users');
          this.tags.push('Website');

          // this.submitPromo()
          // this.pricingObj.services.website.push(item.key)
          if (item.pay) {
            flag = true;
            this.calcPricing(item.price, flag, item);
          }
          if (!item.toggleClass) {
            item.toggleClass = true;
          }
        } else if (item.mobile) {
          if (!this.pricingObj.services.mobile) {
            this.pricingObj.services.mobile = [];
          }
          this.tags.push('Mobile app');
          // this.pricingObj.services.website.push(item.key)
          if (item.pay) {
            flag = true;
            this.calcPricing(this.mobilePrice, flag);
            this.calcPricingCustom(item.setupFee, flag);
          }
          if (!item.toggleClass) {
            item.toggleClass = true;
          }
        } else if (item.tv) {
          if (!this.pricingObj.services.tv) {
            this.pricingObj.services.tv = [];
          }
          this.tags.push('TV app');
          // this.pricingObj.services.website.push(item.key)
          if (item.pay) {
            flag = true;
            // this.calcPricing(this.itemPriceTv, flag)
            this.calcPricing(item.price, flag, item);
            this.calcPricingCustom(item.setupFee, flag);
          }
          if (!item.toggleClass) {
            item.toggleClass = true;
          }
        } else if (item.give) {
          if (!this.pricingObj.services.give) {
            this.pricingObj.services.give = [];
          }
          this.tags.push('Giving platform');
          // this.pricingObj.services.website.push(item.key)
          // if (item.pay) {
          //   flag = true
          //   this.calcPricing(this.itemPrice, flag)
          // }
          if (!item.toggleClass) {
            item.toggleClass = true;
          }
        }

        if (this.prayerWallModule[0].checked && this.mobileAppModule[0].checked && this.tvAppModule[0].checked && this.websiteModule[0].checked && this.giveModule[0].checked) {
          // this.tags.splice(this.tags.indexOf('Website', 0), 1)
          const index = this.tags.indexOf('Interactive Prayer Wall');
          if (index !== -1) {
            this.tags.splice(index, 1);
          }
          const index2 = this.tags.indexOf('Website');
          if (index2 !== -1) {
            this.tags.splice(index2, 1);
          }
          const index3 = this.tags.indexOf('Giving platform');
          if (index3 !== -1) {
            this.tags.splice(index3, 1);
          }
          const index4 = this.tags.indexOf('Mobile app');
          if (index4 !== -1) {
            this.tags.splice(index4, 1);
          }
          const index5 = this.tags.indexOf('TV app');
          if (index5 !== -1) {
            this.tags.splice(index5, 1);
          }
          // this.tags.splice(this.tags.indexOf('Giving platform', 0), 1)
          // this.tags.splice(this.tags.indexOf('Mobile app', 0), 1)
          // this.tags.splice(this.tags.indexOf('TV app', 0), 1)
          this.tags.push('Full package');
        }
      } else {
        // if (!this.mobileAppModule[0].checked || !this.tvAppModule[0].checked || this.websiteModule[0].checked || this.giveModule[0].checked) {
        // this.tags.push('Full package')
        // this.tags.splice(this.tags.indexOf('Full package', 0), 1)
        const index = this.tags.indexOf('Full package');
        if (index !== -1) {
          this.tags.splice(index, 1);
        }
        // }
        // this.promoModule = false
        this.validPromoCode = false;
        if (item.prayer) {
          delete this.pricingObj.services.prayerWall;
          const index = this.tags.indexOf('Interactive Prayer Wall');
          if (index !== -1) {
            this.tags.splice(index, 1);
          }
          if (item.toggleClass) {
            item.toggleClass = false;
          }
          if (item.pay) {
            flag = false;
            this.calcPricing(item.price, flag, item);
          }
        } else if (item.website) {
          // this.pricingObj.services.website.splice(this.pricingObj.services.website.indexOf(item.key), 1)
          // this.calcCustomData(this.additionalOptionsWebsite, e)
          const additionalOptionsWebsite = this.additionalOptionsWebsite.filter((obj) => obj.checked);
          additionalOptionsWebsite.forEach((option) => {
            if (option.customPrice) {
              this.calcPricingCustom(option.price, flag);
            }
            this.pricingObj.services.additionals.splice(this.pricingObj.services.additionals.indexOf(option.key), 1);
            option.checked = false;
          });
          delete this.pricingObj.services.website;
          // this.tags.splice(this.tags.indexOf('Website', 0), 1)
          const index = this.tags.indexOf('Website');
          if (index !== -1) {
            this.tags.splice(index, 1);
          }
          if (item.pay) {
            flag = false;
            this.calcPricing(item.price, flag, item);
          }
          if (item.toggleClass) {
            item.toggleClass = false;
          }
        } else if (item.mobile) {
          this.chooseAllModules(this.selectAllModules[0], false);
          const checkedList = this.mobileAppModulesList.filter((obj) => obj.checked);
          for (let i = 0; i < checkedList.length; i++) {
            checkedList[i].checked = false;
            // this.calcPricing(this.modulePrice, flag)
          }
          let flag = false;
          const checkedListAdditional = this.additionalOptionsMobileApp.filter((obj) => obj.checked);
          // console.log(checkedListAdditional)
          checkedListAdditional.forEach((option) => {
            option.checked = false;
            this.pricingObj.services.additionals.splice(this.pricingObj.services.additionals.indexOf(option.key), 1);
            if (option.pay) {
              this.calcPricing(this.modulePrice, flag);
            } else if (option.customPrice) {
              this.calcPricingCustom(option.price, flag);
            }
          });
          delete this.pricingObj.services.mobile;
          const index = this.tags.indexOf('Mobile app');
          if (index !== -1) {
            this.tags.splice(index, 1);
          }
          // this.tags.splice(this.tags.indexOf('Mobile app', 0), 1)

          if (item.pay) {
            flag = false;
            this.calcPricing(this.mobilePrice, flag);
            this.calcPricingCustom(item.setupFee, flag);
          }
          if (item.toggleClass) {
            item.toggleClass = false;
          }
        } else if (item.tv) {
          // let flag = false
          const checkedList = this.tvAppModulesList.filter((obj) => obj.checked);
          // console.log(checkedList)
          for (let i = 0; i < checkedList.length; i++) {
            checkedList[i].checked = false;
            // this.calcPricing(this.modulePrice, flag)
          }

          const flagAudio = false;
          const checkedListAudio = this.audioPodcastList.filter((obj) => obj.checked);
          // console.log(checkedListAudio)
          for (let i = 0; i < checkedListAudio.length; i++) {
            checkedListAudio[i].checked = false;
            this.pricingObj.services.additionals.splice(this.pricingObj.services.additionals.indexOf(item.key), 1);
            if (checkedListAudio[i].pay) {
              this.calcPricing(this.modulePrice, flagAudio);
            }
          }

          const flagVideo = false;
          const checkedListVideo = this.additionalOptions.filter((obj) => obj.checked);
          // console.log(checkedListAudio)
          for (let i = 0; i < checkedListVideo.length; i++) {
            checkedListVideo[i].checked = false;
            this.pricingObj.services.additionals.splice(this.pricingObj.services.additionals.indexOf(item.key), 1);
            if (checkedListVideo[i].pay) {
              this.calcPricing(this.modulePrice, flagVideo);
            }
          }

          // this.pricingObj.services.website.splice(this.pricingObj.services.website.indexOf(item.key), 1)
          delete this.pricingObj.services.tv;
          const checkedListTV = this.tvAppList.filter((obj) => obj.checked);
          // console.log(checkedListTV)
          for (let i = 0; i < checkedListTV.length; i++) {
            checkedListTV[i].checked = false;
            // console.log(checkedListTV[i])
            // this.calcPricing(this.modulePrice, flag)
          }
          delete this.pricingObj.services.platforms;
          // this.tags.splice(this.tags.indexOf('TV app', 0), 1)
          const index = this.tags.indexOf('TV app');
          if (index !== -1) {
            this.tags.splice(index, 1);
          }

          if (item.pay) {
            flag = false;
            this.calcPricing(item.price, flag, item);
            this.calcPricingCustom(item.setupFee, flag);
          }
          if (item.toggleClass) {
            item.toggleClass = false;
          }
        } else if (item.give) {
          const flag = false;
          const checkedList = this.additionalOptionsGiving.filter((obj) => obj.checked);
          // console.log(checkedList)
          for (let i = 0; i < checkedList.length; i++) {
            checkedList[i].checked = false;
            this.calcPricing(this.modulePrice, flag);
          }
          delete this.pricingObj.services.give;
          // this.tags.splice(this.tags.indexOf('Giving platform', 0), 1)
          const index = this.tags.indexOf('Giving platform');
          if (index !== -1) {
            this.tags.splice(index, 1);
          }
          // if (item.pay) {
          //   flag = false
          //   this.calcPricing(this.itemPrice, flag)
          // }
          if (item.toggleClass) {
            item.toggleClass = false;
          }
        }
      }
      // if (this.pricingObj.services.give !== undefined && this.pricingObj.services.give !== null &&
      //     this.pricingObj.services.mobile !== undefined && this.pricingObj.services.mobile !== null &&
      //     this.pricingObj.services.tv !== undefined && this.pricingObj.services.tv !== null &&
      //     this.pricingObj.services.website !== undefined && this.pricingObj.services.website !== null
      // ) {
      //   this.tags.push('Full package')
      // }
    },
    openDropPricingItem(action) {
      if (this.dropPricingItem === action) {
        this.dropPricingItem = false;
      } else {
        this.dropPricingItem = action;
      }
    },
    fixContainer() {
      this.$nextTick(() => {
        const rect = this.$refs.fixContainer.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        this.$refs.sidebar.style.right = `${rect.left + scrollLeft + 0}px`;
        // console.log(this.$refs.sidebar.style.right = rect.left + scrollLeft + 0 + 'px')
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'assets/sass/utils/variables';
  .error {
    border-color: $color-punch !important;
    background-color: $color-white !important;
  }
  .help {
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 12px;
    color: $color-punch;
    box-shadow: none;
    padding: 0;
    background: transparent;
    transform: translateY(0);
  }

  .pricing-container {
    display: flex;
    width: 100%;
    max-width: 1750px;
    margin: 0 auto;
    padding-left: 57px;
    padding-right: 320px;
    justify-content: space-between;
    /*height: calc(100vh - 126px - 234px);*/

    @media (max-width: 1139px) {
      padding-left: 0;
      padding-right: 0;
      flex-direction: column;
    }

    @media (max-width: 767px) {
      padding-top: 60px;
    }

    &__scroll {
      max-height: 100%;
      padding-top: 50px;
      padding-bottom: 50px;

      @media (max-width: 1139px) {
        padding-top: 20px;
      }

      @media (max-width: 767px) {
        padding-top: 0;
      }

    }

    &__left {
      max-width: 1044px;
      width: 100%;
      padding-bottom: 50px;

      @media (max-width: 1139px) {
        max-width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 30px;
      }

      /*padding-right: 25px;*/
      /*padding-left: 10px;*/
    }

    &__right {
      background: $color-porcelain;
      width: 300px;
      transition: right 0s ease .9s, opacity .2s cubic-bezier(.01,.15,.87,.15) .9s !important;
      /*padding-top: 127px;*/
      /*padding-bottom: 50px;*/
      position: fixed;
      right: 0;
      top: 126px;
      z-index: 50;
      height: calc(100vh - 126px);
      /*height: calc(100vh - 126px - 234px);*/

      @media (max-width: 1139px) {
        width: 100%;
        height: auto;
        position: static;
        background: none;
      }

      &_full {
        height: auto;
      }
    }

    &__title {
      display: none;
      font: 23px / 1.5 $font-global-bold, sans-serif;
      color: $color-shark;
      text-align: center;
      margin-bottom: 24px;

      @media (max-width: 1139px) {
        display: block;
      }

      @media (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 19px;
      }

    }

    &-tabs {

      &-nav {
        display: flex;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          flex-grow: 1;
          cursor: pointer;
          transition: color .2s ease-in, border-color .2s ease-in;
          line-height: 31px;
          font-size: 21px;
          padding-bottom: 9px;
          border-bottom: 1px solid transparent;
          color: $color-alto;
          user-select: none;
          font-family: $font-global-medium, sans-serif;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 26px;
          }

          &-off {
            line-height: 18px;
            font-size: 14px;
            color: $color-alto;
            position: relative;
            top: 4px;
            font-family: $font-global, sans-serif;

            @media (max-width: 767px) {
              font-size: 10px;
              line-height: 13px;
            }
          }

          &_active, &:hover {
            color: $color-curious-blue;
          }

          &_active {
            border-bottom-color: $color-picton-blue;
          }
        }

      }

      &-content {
        padding: 21px 15px 25px 47px ;
        background: $color-picton-blue;

        @media (max-width: 767px) {
          padding-left: 20px;
          padding-right: 8px;
          padding-bottom: 10px;
        }

      }
    }

    &-promo {
      margin-top: 15px;
      margin-bottom: 30px;
      padding-left: 19px;
      padding-right: 15px;

      @media (max-width: 1139px) {
        margin-bottom: 45px;
      }

      @media (max-width: 767px) {
        padding-left: 15px;
      }

      &__wrapper {
        @media (max-width: 1139px) {
          max-width: 344px;
          margin: 0 auto;
        }
      }

      &__input {
        position: relative;

        /*i {
          position: absolute;
          right: 11px;
          top: 15px;
          font-size: 9px;
          color: $color-alto;
          cursor: pointer;
          transition: color 200ms ease-in;

          &.active {
            color: $color-moody-blue;
          }
        }*

        /*&:after {
          content: '\E932';
          font-family: $icon-font;
          position: absolute;
          right: 11px;
          top: 15px;
          font-size: 9px;
          color: $color-alto;
        }*/
      }

      &__text {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        color: $color-jumbo;
        font-family: $font-global, sans-serif;
      }

      &__container {
        display: flex;
        align-items: center;

        button {
          color: $color-white;
          cursor: pointer;
          /*transition: all .15s ease-in;*/
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color-alto;
          height: 40px;
          border-radius: 4px;
          margin-left: 5px;
          width: 80px;
          font-size: 14px;
          pointer-events: none;

          &.active {
            background: $color-moody-blue !important;
            pointer-events: all !important;
          }
        }
      }

      input {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: 1px solid $color-alto;
        font-size: 18px;
        padding-left: 10px;
        padding-right: 35px;
        font-family: $font-global, sans-serif;
        transition: border-color .15s ease-in;
        color: $color-black;

        &:hover {
          border-color: $color-french-gray;
        }

        &:focus {
          border-color: $color-royal-blue-third;
        }

        &::-webkit-input-placeholder {
          color: $color-alto;
        }

        &::-moz-placeholder {
          color: $color-alto;
        }

        &:-moz-placeholder {
          color: $color-alto;
        }

        &:-ms-input-placeholder {
          color: $color-alto;
        }

        &:focus {
          &::-webkit-input-placeholder {
            opacity: 0;
          }

          &::-moz-placeholder {
            opacity: 0;
          }

          &:-moz-placeholder {
            opacity: 0;
          }

          &:-ms-input-placeholder {
            opacity: 0;
          }
        }

      }

      &__message {
        /*margin-bottom: 24px;
        height: 20px;
        font-size: 16px;
        line-height: 24px;
        color: $color-alto;
        margin-top: 5px;*/

        &-pre {
          margin-bottom: 24px;
          height: 20px;
          font-size: 16px;
          line-height: 24px;
          color: $color-alto;
          margin-top: 5px;

          @media (max-width: 767px) {
            margin-bottom: 16px;
          }

        }

        span {
          margin-left: 5px;
          font-family: $font-global, sans-serif;
          font-size: 16px;
        }

        i {
          font-size: 10px;
          position: relative;
          top: -1px;
          color: $color-chateau-green;
        }

        &_error {
          color: $color-crimson;
          i {
            color: $color-crimson;
          }
        }
      }
    }
  }

  .pricing-user {
    font-family: $font-global-semibold, sans-serif;
    font-size: 45px;
    line-height: 56px;
    text-align: center;
    color: $color-shark;
    font-weight: bold;
    margin-bottom: 24px;

    @media (max-width: 767px) {
      font-size: 36px;
      margin-bottom: 0;
    }

  }

  .pricing-text {
    //font-family: $font-global-semibold, sans-serif;
    font-family: $font-global-medium, sans-serif;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    //color: $color-shark;
    color: $color-jumbo;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }

    p + p {
      //color: $color-jumbo;
    }
  }

  .pricing-list {
    margin-right: -9px;
    margin-left: 9px;

    @media (max-width: 1139px) {
      margin: 0;
    }

    & + & {
      margin-top: 37px;

      @media (max-width: 1139px) {
        margin-top: 12px;
      }

    }

    &-item {
      position: relative;
      font-family: $font-global-bold;
      font-size: 31px;
      color: $color-white;
      background: linear-gradient(354.75deg, #747ADB 0%, #79B5DC 100%);
      box-shadow: 0 2px 4px rgba(48, 49, 51, .2);
      padding: 30px 73px 29px 22px;
      font-weight: bold;
      border-radius: 3px;
      /*transition: margin .2s ease-in, transform .2s ease-in, padding .2s ease-in;*/
      transition: border-color .2s ease-in, height .2s ease-in, min-height .2s ease-in, transform .2s ease-in, box-shadow .2s ease-in;
      cursor: pointer;
      user-select: none;
      min-height: 96px;

      @media (max-width: 767px) {
        font-size: 16px;
        padding: 18px 34px 17px 17px;
        min-height: 56px;
      }

      &_tv.pricing-list-item_open {
        /*padding-bottom: 44px !important;*/
        .pricing-list-item-info__text {
          margin-top: -14px;
          margin-bottom: 22px;

          @media (max-width: 767px) {
            margin-top: -8px;
          }

        }

        .form-group + .form-group {
          margin-top: 20px;
        }

        .pricing-list-item-info__desc {
          margin-top: 20px;
          margin-bottom: 16px;
          max-width: 90%;
          width: 100%;
          letter-spacing: .2px;
        }
      }

      &__content {
        display: flex;
        transition: margin .2s ease-in;

        &_prayer {
          .pricing-list-item__checkbox {
            margin-right: 10px;
          }
        }

      }

      &-drop {
        width: 100%;
        padding-top: 40px;
        display: flex;
        padding-left: 63px;
        padding-right: 63px;
        /*transition: opacity .1s;*/

        @media (max-width: 767px) {
          display: block;
          padding: 15px 0 0;
        }

        &__block {
          flex-grow: 1;
          width: 100%;
          position: relative;

          &_indent {
            left: 47px;

            @media (max-width: 767px) {
              left: 0;
            }
          }

          &_mobile {
            @media (max-width: 767px) {
              margin-top: 15px;
            }
          }

          &_tv {
            left: 42px;

            @media (max-width: 767px) {
              left: 0;
              margin-top: 10px;
            }
          }
        }
      }

      &-info {

        & + & {
          margin-top: 42px;

          @media (max-width: 767px) {
            margin-top: 15px;
          }

          .form-group + .form-group {
             position: relative;
             top: 6px;

            @media (max-width: 767px) {
              top: 0;
            }

          }
        }

        &__title {
          margin-bottom: 14px;
          font-size: 21px;
          line-height: 32px;
          color: $color-shark;
          font-family: $font-global-semibold, sans-serif;

          @media (max-width: 767px) {
            font-size: 11px;
            line-height: 20px;
            margin-bottom: 5px;
          }

        }

        &__text {
          margin-top: -12px;
          margin-bottom: 15px;
          font: 14px / 18px $font-global;
          color: $color-jumbo;

          @media (max-width: 767px) {
            font-size: 10px;
            line-height: 12px;
            margin-top: -8px;
          }

        }

        &__p {
          & + & {
            margin-top: 16px;
          }
          font: 18px / 30px $font-global;
          color: $color-shark;

          a {
            font-family: $font-global-bold;
            color: $color-shark;
            text-decoration: none;
          }

          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 20px;

            & + & {
              margin-top: 4px;
            }
          }

        }

        &__ul {
          margin-bottom: 15px;

          @media (max-width: 767px) {
            margin-bottom: 0;
          }

          &_width {
            max-width: 390px;
            margin-bottom: 16px;
          }

          li {
            position: relative;
            padding-left: 12px;
            font: 18px / 30px $font-global;
            color: $color-shark;

            @media (max-width: 767px) {
              font-size: 10px;
              line-height: 16px;
            }

            &:before {
              position: absolute;
              top: 0;
              left: 0;
              content: '•';
              font-family: $font-global-bold;
            }
          }
        }

        &__desc {
          margin-top: 15px;
          margin-bottom: 15px;
          font-size: 18px;
          line-height: 24px;
          color: $color-shark;
          font-style: italic;
          font-family: $font-global;
          font-weight: 400;

          @media (max-width: 767px) {
            font-size: 13px;
            line-height: 18px;
          }

        }

        &__btn {
          margin: 10px auto 0;
          display: flex;
          width: 280px;
          max-width: 80%;
          height: 48px;
          border-radius: 5px;
          font-size: 16px;
          color: $color-white;
          transition: all 0.3s;
          position: relative;
          overflow: hidden;
          z-index: 1;
          border: 1px solid transparent;
          text-decoration: none;
          font-family: $font-global;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-turquoise;
            border: 1px solid $color-turquoise;
            border-radius: 5px;
            z-index: -2;
          }

          &:before {
            box-sizing: border-box;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $color-white;
            border: 2px solid $color-turquoise;
            transition: all 0.3s;
            border-radius: 5px;
            z-index: -1;
          }

          &:hover {
            color: $color-turquoise;

            &:before {
              width: 100%;
            }

          }

        }
      }

      &:hover {
        position: relative;
        z-index: 2;
        //margin-left: -15px;
        //margin-right: -15px;
        /*padding: 30px 73px 29px 22px;*/
        /*transform: scaleY(1.05);*/
        background: linear-gradient(72deg, #47c4e0 0%, #5372f0 100%);

        /*
        .pricing-list-item__content {
          margin: 0 -12px;
        }*/
      }

      &_checked {
        background: linear-gradient(-72deg, #8796d8 0%, #4ddec3 100%) !important;

        &-free {
          background: linear-gradient(-72deg, #6B40F3 0%, #EA59C1 100%) !important;
        }
      }

      &_open {
        /*margin-left: 0!important;*/
        /*margin-right: 0!important;*/
        /*padding: 27px 73px 49px 22px!important;*/
        /*transform: scaleY(1)!important;*/
        color: $color-shark;
        background: $color-white!important;
        border: 1px solid $color-royal-blue-second;
        box-shadow: 0 8px 16px rgba(48, 49, 51, .2);

        &:hover {
          .pricing-list-item__content {
            margin: 0;
          }
        }

        .pricing-list-item__arrow {
          transform: rotate(0);
          color: $color-moody-blue;
        }

      }

      & + & {
        margin-top: 8px;

        @media (max-width: 1139px) {
          margin-top: 4px;
        }

      }

      &__checkbox {
        padding-right: 3px;

        &-text {
          font-size: 14px;
          font-family: $font-global;
          font-weight: 400;
          line-height: 18px;
          margin-left: 67px;
          margin-bottom: -16px;

          @media (max-width: 767px) {
            font-size: 7px;
            line-height: 8px;
            margin-left: 31px;
            margin-top: 3px;
            margin-bottom: -10px;
          }

        }

      }

      &__medal {
        align-items: flex-start;
        min-width: 46px;
        display: flex;
        position: relative;
        top: -7px;

        img {
          max-width: 100%;
        }

        @media (max-width: 1430px) {
          width: 30px;
          min-width: 30px;
          top: 0;
        }

        @media (max-width: 766px) {
          width: 15px;
          min-width: 15px;
        }

      }

      &__price {
        display: flex;
        gap: 25px;
        margin-left: auto;
        text-align: right;
        letter-spacing: 0;
        font-family: $font-global-semibold, sans-serif;

        @media (max-width: 1430px) {
          gap: 10px;
        }

        &-text {
          font-size: 14px;
          font-family: $font-global;
          font-weight: 400;
          line-height: 18px;
          margin-top: 1px;
          margin-bottom: -19px;

          @media (max-width: 767px) {
            font-size: 7px;
            line-height: 8px;
          }

        }

      }

      &__arrow {
        position: absolute;
        top: 41px;
        right: 26px;
        font-size: 11px;
        transform: rotate(90deg);
        transition: transfoem .1s ease-in, color .1s ease-in;

        @media (max-width: 767px) {
          top: 23px;
          right: 13px;
          font-size: 7px;
        }

      }

    }
  }

  .generate-pdf {
    width: 170px;
    margin: 0 auto;
    text-align: center;
    color: $color-jumbo;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 35px;

    &__icon {
      margin-bottom: 10px;
      font-size: 33px;
    }
  }

  .sending-data {
    display: flex;
    flex-direction: column;
    align-items: center;

    button, a {
      width: 160px;
      border-color: $color-alto;
      color: $color-jumbo;
      transition: opacity .2s ease-in;
      text-decoration: none;

      &:hover {
        opacity: .6;
      }
    }

    a {
      margin-top: 16px;
    }
  }

  .form-group {
    & + & {
      margin-top: 15px;
    }

    &-text {
      margin-top: 5px;
      font: 13px / 15px $font-global;
      margin-left: 29px;
    }

  }

  .form-group {
    &_offset {
      margin-left: 10px;
    }
  }

  .btn {
    &_inline {
      display: inline-flex;
      text-decoration: none;
      //font-family: $font-global, sans-serif;
    }
  }

</style>

<style lang="scss">
  @import 'assets/sass/utils/variables';

  .body {
    &_pricing {
      min-width: 1200px;

      @media (max-width: 1139px) {
        min-width: 320px;
      }
    }
  }
  .pricing-list {
    &-item {
      &_open {
        .checkbox__input:checked + .checkbox__icon:before {
          color: $color-moody-blue!important;
        }

        .checkbox_white {
          .checkbox__icon {
            border-color: $color-moody-blue!important;
            color: $color-moody-blue!important;
          }

          .checkbox__input:checked + .checkbox__icon:before {
            color: $color-moody-blue!important;
          }
        }

      }
    }
  }

  .pricing-list-item-info {

    &_mr {
      max-width: 350px;
      width: 100%;
    }

    .form-group-text {
      @media (max-width: 767px) {
        font-size: 10px;
        line-height: 13px;
        margin-left: 26px;
      }

      a {
        color: $color-moody-blue;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

      }
    }
  }

  .generate-pdf__text {
    font-family: $font-global-medium, sans-serif;
    font-size: 14px;
    color: $color-jumbo;
  }

  .pricing-btn {
    font-family: $font-global-medium, sans-serif;
    font-size: 21px;

    &_disabled {
      background: $color-gray;
      pointer-events: none;
    }
  }

  .pricing-list-item__content {
    .checkbox__value {
      white-space: normal;
    }
  }
</style>
