<template>
  <transition
    name="fade-block-wait"
    appear
    mode="out-in"
  >
    <div
      v-shortkey="['esc']"
      class="modals-wait"
      @contextmenu.self.prevent
      @click="$emit('update:showModalWait', false)"
      @shortkey="$emit('update:showModalWait', false)"
    >
      <div
        class="modals-wait__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          class="modals-wait__close"
          @click="$emit('update:showModalWait', false)"
        >
          <i class="icon-close-modal"></i>
        </div>
        <div class="modals-wait__title">
          Wait!
        </div>
        <div class="modals-wait__desc">
          <div>We highly recommend getting a mobile and TV app</div>
          <div>and save $1,000/year. This way you will get a <span>complete,</span></div>
          <div><span>all-in-one</span> best-practices platform for your ministry for a special pricing!</div>
        </div>
        <div class="modals-wait-list">
          <button
            v-for="(item, index) in btns"
            :key="index"
            ref="modalItem"
            class="modals-wait-item"
            :class="[{'modals-wait-item_active': activeItemId === index},{'modals-wait-item_active': item.active}, item.classNameItem]"
            @click="setActiveItemId(index)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
      <form
        ref="form"
        action="https://welcome.impactfactors.net/w/index.php"
        method="post"
        style="display: none"
      >
        <input
          id="signup"
          type="hidden"
          name="signup"
          :value="signup"
        >
        <input
          id="org"
          type="hidden"
          name="org"
          :value="org"
        >
      </form>
    </div>
  </transition>
</template>

<script>
// import pricingApi from '@/api/pricingApi'
export default {
  name: 'ModalsWait',
  props: ['showModalWait', 'tvAppModule', 'mobileAppModuleVar', 'tvAppModuleVar'],
  data() {
    return {
      signup: 1,
      org: '',
      activeItemId: -1,
      btns: [
        {
          name: 'Yes, add Mobile & TV App!',
          classNameItem: 'modals-wait-item_first',
          active: false,
        },
        {
          name: 'Yes, add Mobile App only!',
          classNameItem: 'modals-wait-item_second',
          active: false,
        },
        {
          name: 'Skip both and lose $1,000/year in savings',
          classNameItem: 'modals-wait-item_third',
        },
      ],
    };
  },
  computed: {
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
  },
  beforeMount() {
    if (this.mobileAppModuleVar.checked) {
      this.activeItemId = 1;
      this.btns[0].name = 'Yes, add TV app & Podcast';
    } else if (this.tvAppModuleVar.checked) {
      this.activeItemId = 0;
      this.btns[0].name = 'Yes, add TV app & Podcast';
    }
  },
  methods: {
    setActiveItemId(itemIndex) {
      this.activeItemId = itemIndex;
      if (itemIndex === 2) {
        this.$emit('update:showModalWait', false);
        this.subForm();
        // && !this.mobileAppModuleVar.checked
      } else if (itemIndex === 1) {
        this.$emit('mobileAppModule', true, true, '', this.mobileAppModuleVar);
        this.btns[0].active = true;
        this.btns[1].active = true;
        this.subForm();
        this.$emit('update:showModalWait', false);
      } else if (itemIndex === 0) {
        if (!this.mobileAppModuleVar.checked) {
          this.$emit('mobileAppModule', true, true, '', this.mobileAppModuleVar);
        }
        this.btns[0].active = true;
        this.btns[1].active = true;
        this.$emit('mobileAppModule', true, true, '', this.tvAppModuleVar);
        this.subForm();
        this.$emit('update:showModalWait', false);
      }
    },
    // sendData: function () {
    //   console.log('SEND DATA ++++++++++++++++++')
    //   pricingApi.sendPricing({
    //     org: JSON.stringify(this.pricingObj)
    //   }, (response) => {
    //     console.log(response)
    //   }, (err) => {
    //     console.log(err)
    //   })
    // }
    subForm() {
      window.fbq('trackCustom', 'Sign Up Form Step 3 - Pricing');
      this.$router.push({ name: 'pricingStep1' });
    },
  },
};
</script>

<style lang="scss">
  @import '../../assets/sass/utils/variables';

  .modals-wait {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    /*transition: opacity .5s ease-in;*/
    background: rgba(0, 0, 0, .8);
    /*&__bg {*/
      /*background: rgba(0, 0, 0, 0);*/
      /*animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;*/
    /*}*/

    &__content {
      max-width: 1092px;
      width: 100%;
      background: $color-white;
      border-radius: 10px;
      position: relative;
      padding: 65px 15px;

      @media (max-width: 767px) {
        padding: 20px 15px;
      }

    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      line-height: 40px;
      font-size: 31px;
      color: $color-crimson;
      text-align: center;
      margin-bottom: 16px;

      @media (max-width: 1139px) {
        font-size: 25px;
      }

      @media (max-width: 767px) {
        font-size: 20px;
        margin-bottom: 10px;
      }

    }

    &__desc {
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      font-family: $font-global;
      margin-bottom: 38px;
      color: $color-jumbo;

      @media (max-width: 1139px) {
        font-size: 20px;
        line-height: 28px;
      }

      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 25px;
      }

      span {
        color: $color-black;
        font-family: $font-global-medium;
      }
    }

    &-list {
      display: flex;
      justify-content: center;
    }

    &-item  {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-global-semibold;
      font-size: 17px;
      color: $color-white;
      background: $color-fruit-salad;
      height: 48px;
      width: 100%;
      margin-right: 2px;
      cursor: pointer;
      border: 1px solid transparent;
      transition: all .15s ease-in;
      padding-right: 5px;

      @media (max-width: 1139px) {
        font-size: 13px;
      }

      @media (max-width: 1139px) {
        font-size: 10px;
      }

      &:hover {
        background: $color-white;
        color: $color-jumbo;
        border-color: $color-jumbo;
      }

      &:last-child {
        margin-right: 0;
      }

      &_first {
        max-width: 302px;
        border-radius: 30px 0 0 30px;
      }

      &_second {
        max-width: 296px;
      }

      &_third {
        max-width: 348px;
        border-radius:  0 30px 30px 0;
        background: $color-white;
        color: $color-jumbo;
        border-color: $color-jumbo;
        pointer-events: all !important;

        &:hover {
          opacity: .6;
        }
      }

      &_active {
        background: $color-white;
        color: $color-jumbo;
        border-color: $color-jumbo;
        pointer-events: none;
      }
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity:0;
    }
    100% {
      transform: translateY(0);
      opacity:1;
    }
  }
  @keyframes scaleDown {
    0% {
      transform: translateY(0);
      opacity:1;
    }
    100% {
      transform: translateY(1000px);
      opacity:0;
    }
  }
</style>
