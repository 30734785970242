<template>
  <transition
    name="fade-block-wait"
    appear
    mode="out-in"
  >
    <div
      v-shortkey="['esc']"
      class="modals-wait-giving"
      @contextmenu.self.prevent
      @click="showModalWaitGiving = false"
      @shortkey="showModalWaitGiving = false"
    >
      <div
        class="modals-wait-giving__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          class="modals-wait-giving__close"
          @click="showModalWaitGiving = false"
        >
          <i class="icon-close-modal"></i>
        </div>
        <div class="modals-wait-giving__title">
          Wait!
        </div>
        <div class="modals-wait-giving__desc">
          <div>
            We highly recommend to sign up for our Giving
            platform and save on average 40%
          </div>
          <div>
            in fees while getting best-practices giving experience and fundraising tools.
          </div>
        </div>
        <div class="modals-wait-giving-list">
          <button
            v-for="(item, index) in btns"
            :key="index"
            ref="modalItem"
            class="modals-wait-giving-item"
            :class="[{'modals-wait-giving-item_active': activeItemId === index},{'modals-wait-giving-item_active': item.active}, item.classNameItem]"
            @click="setActiveItemId(index)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
      <form
        ref="form"
        action="https://welcome.impactfactors.net/w/index.php"
        method="post"
        style="display: none"
      >
        <input
          id="signup"
          type="hidden"
          name="signup"
          :value="signup"
        >
        <input
          id="org"
          type="hidden"
          name="org"
          :value="org"
        >
      </form>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalsWaitGiving',
  props: ['giveModuleVar'],
  data() {
    return {
      signup: 1,
      org: '',
      activeItemId: -1,
      btns: [
        {
          name: 'Yes, add Giving Platform',
          classNameItem: 'modals-wait-giving-item_first',
          active: false,
        },
        {
          name: 'Skip Giving Platform',
          classNameItem: 'modals-wait-giving-item_second',
          active: false,
        },
      ],
    };
  },
  computed: {
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    showModalWaitGiving: {
      get() {
        return this.$store.getters.showModalWaitGiving;
      },
      set(data) {
        this.$store.commit('setShowModalWaitGiving', data);
      },
    },
  },
  beforeMount() {
  },
  methods: {
    setActiveItemId(itemIndex) {
      this.activeItemId = itemIndex;
      if (itemIndex === 1) {
        this.showModalWaitGiving = false;
        this.subForm();
      } else if (itemIndex === 0) {
        this.$emit('mobileAppModule', true, true, '', this.giveModuleVar);
        this.showModalWaitGiving = false;
        this.subForm();
      }
    },
    subForm() {
      window.fbq('trackCustom', 'Sign Up Form Step 3 - Pricing');
      this.$router.push({ name: 'pricingStep1' });
    },
    sendData() {
    },
  },
};
</script>

<style lang="scss">
  @import '../../assets/sass/utils/variables';

  .modals-wait-giving {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .8);

    &__content {
      max-width: 1092px;
      width: 100%;
      background: $color-white;
      border-radius: 10px;
      position: relative;
      padding: 65px 15px;
    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      line-height: 40px;
      font-size: 31px;
      color: $color-crimson;
      text-align: center;
      margin-bottom: 16px;
    }

    &__desc {
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      font-family: $font-global;
      margin-bottom: 38px;
      color: $color-jumbo;

      span {
        color: $color-black;
        font-family: $font-global-medium;
      }
    }

    &-list {
      display: flex;
      justify-content: center;
    }

    &-item  {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-global-semibold;
      font-size: 17px;
      color: $color-white;
      background: $color-fruit-salad;
      height: 48px;
      width: 100%;
      margin-right: 2px;
      cursor: pointer;
      border: 1px solid transparent;
      transition: all .15s ease-in;
      padding-right: 5px;

      &:hover {
        background: $color-white;
        color: $color-jumbo;
        border-color: $color-jumbo;
      }

      &:last-child {
        margin-right: 0;
      }

      &_first {
        max-width: 302px;
        border-radius: 30px 0 0 30px;
      }

      &_second {
        max-width: 302px;
        border-radius:  0 30px 30px 0;
        background: $color-white;
        color: $color-jumbo;
        border-color: $color-jumbo;
        pointer-events: all !important;

        &:hover {
          opacity: .6;
        }
      }

      &_active {
        background: $color-white;
        color: $color-jumbo;
        border-color: $color-jumbo;
        pointer-events: none;
      }
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity:0;
    }
    100% {
      transform: translateY(0);
      opacity:1;
    }
  }
  @keyframes scaleDown {
    0% {
      transform: translateY(0);
      opacity:1;
    }
    100% {
      transform: translateY(1000px);
      opacity:0;
    }
  }
</style>
