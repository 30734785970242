<template>
  <div class="pricing-form">
    <div class="pricing-form-row">
      <div class="pricing-form-row__title">
        Setup Fee
      </div>
      <div class="pricing-form-group">
        <div class="pricing-form-group__label">
          $
        </div>
        <div class="pricing-form-group__input">
          {{ setupFee }}
        </div>
      </div>
      <div class="pricing-form-row__text">
        one-time
      </div>
    </div>
    <div class="pricing-form-row">
      <div class="pricing-form-row__title">
        Monthly Fee
      </div>
      <div class="pricing-form-group">
        <div class="pricing-form-group__label">
          $
        </div>
        <div class="pricing-form-group__input pricing-form-group__input_shown">
          {{ monthlyFee }}
        </div>
        <ICountUp
          :startVal="startVal"
          :endVal="endVal"
          :delay="delay"
          :options="options"
          class="pricing-form-group__input pricing-form-group__input_hidden"
        />
        <div class="pricing-form-group__text">
          /month
        </div>
      </div>
      <template v-if="errorPromo && validPromoCode">
        <div class="pricing-form-row__text">
          <span>25% discount applied for monthly payments.</span>
        </div>
      </template>
      <template v-else>
        <div class="pricing-form-row__text">
          <template v-if="monthlyFee > -1">
            <span>Ask us about options to</span>
            <span>reduce monthly payment</span>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ICountUp from 'vue-countup-v2';

export default {
  name: 'MonthlyContent',
  components: {
    ICountUp,
  },
  props: {
    isPrayerwall: {
      type: Boolean,
      default: false,
    },
  },
  // props: ['customDesign', 'errorPromo', 'validPromoCode'],
  data() {
    return {
      startVal: 75,
      delay: 0,
      endVal: 0,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ' ',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
      // showInitialPrice: true,
      // showDiscountPrice: false,
      discountShownOnce: false,
    };
  },
  computed: {
    validPromoCode: {
      get() {
        return this.$store.getters.validPromoCode;
      },
      set(data) {
        this.$store.commit('setValidPromoCode', data);
      },
    },
    errorPromo: {
      get() {
        return this.$store.getters.errorPromo;
      },
      set(data) {
        this.$store.commit('setErrorPromo', data);
      },
    },
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    // monthlyFee: function () {
    //   return this.pricingObj.amount
    // },
    setupFee() {
      const { promo = 0, services = {}, setup } = this.pricingObj;
      const { additionals = [] } = services;
      const customDesign = additionals.filter((item) => item.includes('custom_design'));
      const customDesignPrice = customDesign.length ? 250 : 0;
      const setupFee = Object.keys(services).filter((item) => item === 'mobile' || item === 'tv');
      const setupFeePrice = setupFee.length ? 250 : 0;
      const fullSetupPrice = promo && this.validPromoCode
        ? customDesignPrice + setupFeePrice
        : setup;
      return fullSetupPrice;
    },
    monthlyFee() {
      const { promo = 0, amount } = this.pricingObj;
      const promoPrayerDiscount = 0;
      const validPromoCode = this.isPrayerwall
        ? promo === 'trial12' || 'ppunlim'
        : this.validPromoCode;
      const promoDiscount = this.isPrayerwall ? promoPrayerDiscount : 0.75;
      return promo && validPromoCode
        ? this.convertNumber((amount * promoDiscount).toFixed())
        : this.convertNumber(amount.toFixed());
    },
  },
  methods: {
    formatter(num) {
      return num.toFixed(0);
    },
    convertNumber(number) {
      return String(number).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '../assets/sass/utils/variables';

  .pricing-form {

    @media (max-width: 1139px) {
      display: flex;
      justify-content: center;
      max-width: 550px;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      justify-content: space-between;
    }

    &-row {
      text-align: center;
      color: $color-white;

      & + & {
        margin-top: 19px;

        @media (max-width: 1139px) {
          margin-top: 0;
          margin-left: 36px;
        }

        @media (max-width: 767px) {
          margin-left: 8px;
        }

        .pricing-form-group__label {
          top: -2px;
        }

        .pricing-form-group__text {
          margin-left: 10px;

          @media (max-width: 767px) {
            margin-left: 7px;
          }

        }

        .pricing-form-row__text {
          span {
            display: block;
          }
        }
      }

      &__title {
        margin-bottom: 5px;
        font-size: 21px;
        line-height: 31px;
        padding-right: 28px;
        font-family: $font-global-bold, sans-serif;

        @media (max-width: 1139px) {
          padding-right: 0;
          padding-left: 34px;
          max-width: 174px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 23px;
          padding-left: 20px;
          width: 139px;
        }

      }

      &__text {
        margin-top: 8px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        margin-left: -30px;
        height: 36px;
        font-family: $font-global, sans-serif;

        @media (max-width: 1139px) {
          margin-left: 0;
          padding-left: 28px;
          max-width: 180px;
        }

        @media (max-width: 767px) {
          font-size: 10px;
          line-height: 12px;
          width: 135px;
        }

      }

    }

    &-group {
      display: flex;
      align-items: center;
      position: relative;

      &__label {
        width: 19px;
        margin-right: 15px;
        font-size: 31px;
        color: $color-white;
        position: relative;
        top: 10px;
        left: 2px;

        @media (max-width: 767px) {
          font-size: 24px;
          top: 1px;
          margin-right: 8px;
        }
      }

      &__input {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 64px;
        border: 2px solid rgba($color-white, .5);
        border-radius: 5px;
        font-size: 36px;
        color: $color-white;
        line-height: 1px;

        @media (max-width: 767px) {
          width: 105px;
          font-size: 33px;
          height: 48px;
        }

        &_hidden {
          position: absolute;
          left: 34px;
          top: 0;
          z-index: -1;
        }
      }

      &__text {
        font-size: 14px;
        line-height: 18px;
        margin-left: 5px;
        width: 47px;
        text-align: left;

        @media (max-width: 767px) {
          font-size: 10px;
          line-height: 12px;
        }

      }

    }
  }
</style>
