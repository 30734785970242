export const COUNTRY_LIST = [
  {
    id: '004',
    name: 'Afghanistan',
    code: 'AF',
    dial: '93',
    lang: [
      'fa',
      'ps',
      'uz',
    ],
  },
  {
    id: '008',
    name: 'Albania',
    code: 'AL',
    dial: '355',
    lang: [
      'sq',
      'el',
    ],
  },
  {
    id: '012',
    name: 'Algeria',
    code: 'DZ',
    dial: '213',
    lang: [
      'ar',
      'fr',
    ],
  },
  {
    id: '016',
    name: 'American Samoa',
    code: 'AS',
    dial: '1684',
    lang: [
      'sm',
      'en',
    ],
  },
  {
    id: '020',
    name: 'Andorra',
    code: 'AD',
    dial: '376',
    lang: [
      'ca',
      'fr',
      'es',
    ],
  },
  {
    id: '024',
    name: 'Angola',
    code: 'AO',
    dial: '244',
    lang: [
      'pt',
      'kg',
    ],
  },
  {
    id: '660',
    name: 'Anguilla',
    code: 'AI',
    dial: '1264',
    lang: [
      'en',
    ],
  },
  {
    id: '010',
    name: 'Antarctica',
    code: 'AQ',
    dial: '672',
    lang: [],
  },
  {
    id: '028',
    name: 'Antigua and Barbuda',
    code: 'AG',
    dial: '1268',
    lang: [
      'en',
    ],
  },
  {
    id: '032',
    name: 'Argentina',
    code: 'AR',
    dial: '54',
    lang: [
      'es',
      'it',
      'en',
    ],
  },
  {
    id: '051',
    name: 'Armenia',
    code: 'AM',
    dial: '374',
    lang: [
      'hy',
      'ku',
    ],
  },
  {
    id: '533',
    name: 'Aruba',
    code: 'AW',
    dial: '297',
    lang: [
      'es',
      'en',
      'nl',
    ],
  },
  {
    id: '036',
    name: 'Australia',
    code: 'AU',
    dial: '61',
    lang: [
      'en',
      'zh',
      'it',
    ],
  },
  {
    id: '040',
    name: 'Austria',
    code: 'AT',
    dial: '43',
    lang: [
      'de',
      'tr',
      'sr',
    ],
  },
  {
    id: '031',
    name: 'Azerbaijan',
    code: 'AZ',
    dial: '994',
    lang: [
      'az',
      'ru',
      'hy',
    ],
  },
  {
    id: '044',
    name: 'Bahamas',
    code: 'BS',
    dial: '1242',
    lang: [
      'en',
      'ht',
    ],
  },
  {
    id: '048',
    name: 'Bahrain',
    code: 'BH',
    dial: '973',
    lang: [
      'ar',
      'en',
      'fa',
    ],
  },
  {
    id: '050',
    name: 'Bangladesh',
    code: 'BD',
    dial: '880',
    lang: [
      'bn',
    ],
  },
  {
    id: '052',
    name: 'Barbados',
    code: 'BB',
    dial: '1246',
    lang: [
      'en',
    ],
  },
  {
    id: '112',
    name: 'Belarus',
    code: 'BY',
    dial: '375',
    lang: [
      'be',
      'ru',
      'uk',
    ],
  },
  {
    id: '056',
    name: 'Belgium',
    code: 'BE',
    dial: '32',
    lang: [
      'nl',
      'fr',
      'de',
    ],
  },
  {
    id: '084',
    name: 'Belize',
    code: 'BZ',
    dial: '501',
    lang: [
      'en',
      'es',
    ],
  },
  {
    id: '204',
    name: 'Benin',
    code: 'BJ',
    dial: '229',
    lang: [
      'fr',
      'yo',
    ],
  },
  {
    id: '060',
    name: 'Bermuda',
    code: 'BM',
    dial: '1441',
    lang: [
      'en',
      'pt',
    ],
  },
  {
    id: '064',
    name: 'Bhutan',
    code: 'BT',
    dial: '975',
    lang: [
      'dz',
    ],
  },
  {
    id: '068',
    name: 'Bolivia',
    code: 'BO',
    dial: '591',
    lang: [
      'es',
      'qu',
      'ay',
    ],
  },
  {
    id: '535',
    name: 'Bonaire',
    code: 'BQ',
    dial: '599',
    lang: [],
  },
  {
    id: '070',
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    dial: '387',
    lang: [
      'bs',
      'sr',
      'hr',
    ],
  },
  {
    id: '072',
    name: 'Botswana',
    code: 'BW',
    dial: '267',
    lang: [
      'tn',
      'en',
    ],
  },
  {
    id: '074',
    name: 'Bouvet Island',
    code: 'BV',
    dial: '47',
    lang: [],
  },
  {
    id: '076',
    name: 'Brazil',
    code: 'BR',
    dial: '55',
    lang: [
      'pt',
      'es',
      'de',
    ],
  },
  {
    id: '086',
    name: 'British Indian Ocean Territory',
    code: 'IO',
    dial: '246',
    lang: [],
  },
  {
    id: '096',
    name: 'Brunei Darussalam',
    code: 'BN',
    dial: '673',
    lang: [
      'ms',
      'en',
      'zh',
    ],
  },
  {
    id: '100',
    name: 'Bulgaria',
    code: 'BG',
    dial: '359',
    lang: [
      'bg',
      'tr',
    ],
  },
  {
    id: '854',
    name: 'Burkina Faso',
    code: 'BF',
    dial: '226',
    lang: [
      'fr',
    ],
  },
  {
    id: '108',
    name: 'Burundi',
    code: 'BI',
    dial: '257',
    lang: [
      'rn',
      'fr',
    ],
  },
  {
    id: '116',
    name: 'Cambodia',
    code: 'KH',
    dial: '855',
    lang: [
      'km',
    ],
  },
  {
    id: '120',
    name: 'Cameroon',
    code: 'CM',
    dial: '237',
    lang: [
      'en',
      'fr',
    ],
  },
  {
    id: '124',
    name: 'Canada',
    code: 'CA',
    dial: '1',
    lang: [
      'en',
      'fr',
      'pa',
    ],
  },
  {
    id: '132',
    name: 'Cape Verde',
    code: 'CV',
    dial: '238',
    lang: [
      'pt',
    ],
  },
  {
    id: '136',
    name: 'Cayman Islands',
    code: 'KY',
    dial: '1345',
    lang: [
      'en',
      'es',
      'tl',
    ],
  },
  {
    id: '140',
    name: 'Central African Republic',
    code: 'CF',
    dial: '236',
    lang: [
      'fr',
    ],
  },
  {
    id: '148',
    name: 'Chad',
    code: 'TD',
    dial: '235',
    lang: [
      'fr',
      'en',
    ],
  },
  {
    id: '152',
    name: 'Chile',
    code: 'CL',
    dial: '56',
    lang: [
      'es',
      'en',
    ],
  },
  {
    id: '156',
    name: 'China',
    code: 'CN',
    dial: '86',
    lang: [
      'zh',
    ],
  },
  {
    id: '162',
    name: 'Christmas Island',
    code: 'CX',
    dial: '61',
    lang: [
      'en',
      'zh',
      'ms',
    ],
  },
  {
    id: '166',
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    dial: '61',
    lang: [
      'ms',
      'en',
    ],
  },
  {
    id: '170',
    name: 'Colombia',
    code: 'CO',
    dial: '57',
    lang: [
      'es',
    ],
  },
  {
    id: '174',
    name: 'Comoros',
    code: 'KM',
    dial: '269',
    lang: [
      'ar',
      'fr',
    ],
  },
  {
    id: '178',
    name: 'Congo',
    code: 'CG',
    dial: '242',
    lang: [
      'fr',
      'ln',
    ],
  },
  {
    id: '180',
    name: 'Democratic Republic of the Congo',
    code: 'CD',
    dial: '243',
    lang: [
      'fr',
      'ln',
    ],
  },
  {
    id: '184',
    name: 'Cook Islands',
    code: 'CK',
    dial: '682',
    lang: [
      'en',
      'mi',
    ],
  },
  {
    id: '188',
    name: 'Costa Rica',
    code: 'CR',
    dial: '506',
    lang: [
      'es',
      'en',
    ],
  },
  {
    id: '191',
    name: 'Croatia',
    code: 'HR',
    dial: '385',
    lang: [
      'hr',
      'sr',
    ],
  },
  {
    id: '192',
    name: 'Cuba',
    code: 'CU',
    dial: '53',
    lang: [
      'es',
    ],
  },
  {
    id: '196',
    name: 'Cyprus',
    code: 'CY',
    dial: '357',
    lang: [
      'el',
      'tr',
      'en',
    ],
  },
  {
    id: '203',
    name: 'Czech Republic',
    code: 'CZ',
    dial: '420',
    lang: [
      'cs',
      'sk',
    ],
  },
  {
    id: '384',
    name: 'Cote dIvoire',
    code: 'CI',
    dial: '225',
    lang: [
      'fr',
    ],
  },
  {
    id: '208',
    name: 'Denmark',
    code: 'DK',
    dial: '45',
    lang: [
      'da',
      'fo',
      'en',
    ],
  },
  {
    id: '262',
    name: 'Djibouti',
    code: 'DJ',
    dial: '253',
    lang: [
      'fr',
      'ar',
      'so',
    ],
  },
  {
    id: '212',
    name: 'Dominica',
    code: 'DM',
    dial: '1767',
    lang: [
      'en',
      'fr',
    ],
  },
  {
    id: '214',
    name: 'Dominican Republic',
    code: 'DO',
    dial: '1809',
    lang: [
      'es',
    ],
  },
  {
    id: '218',
    name: 'Ecuador',
    code: 'EC',
    dial: '593',
    lang: [
      'es',
      'qu',
    ],
  },
  {
    id: '818',
    name: 'Egypt',
    code: 'EG',
    dial: '20',
    lang: [
      'ar',
      'en',
      'fr',
    ],
  },
  {
    id: '222',
    name: 'El Salvador',
    code: 'SV',
    dial: '503',
    lang: [
      'es',
    ],
  },
  {
    id: '226',
    name: 'Equatorial Guinea',
    code: 'GQ',
    dial: '240',
    lang: [
      'es',
      'fr',
    ],
  },
  {
    id: '232',
    name: 'Eritrea',
    code: 'ER',
    dial: '291',
    lang: [
      'ti',
      'ar',
      'en',
    ],
  },
  {
    id: '233',
    name: 'Estonia',
    code: 'EE',
    dial: '372',
    lang: [
      'et',
      'ru',
      'uk',
    ],
  },
  {
    id: '231',
    name: 'Ethiopia',
    code: 'ET',
    dial: '251',
    lang: [
      'om',
      'am',
    ],
  },
  {
    id: '238',
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    dial: '500',
    lang: [
      'en',
      'es',
    ],
  },
  {
    id: '234',
    name: 'Faroe Islands',
    code: 'FO',
    dial: '298',
    lang: [
      'fo',
      'da',
    ],
  },
  {
    id: '242',
    name: 'Fiji',
    code: 'FJ',
    dial: '679',
    lang: [
      'en',
      'fj',
    ],
  },
  {
    id: '246',
    name: 'Finland',
    code: 'FI',
    dial: '358',
    lang: [
      'fi',
      'sv',
    ],
  },
  {
    id: '250',
    name: 'France',
    code: 'FR',
    dial: '33',
    lang: [
      'fr',
    ],
  },
  {
    id: '254',
    name: 'French Guiana',
    code: 'GF',
    dial: '594',
    lang: [],
  },
  {
    id: '258',
    name: 'French Polynesia',
    code: 'PF',
    dial: '689',
    lang: [
      'fr',
    ],
  },
  {
    id: '260',
    name: 'French Southern Territories',
    code: 'TF',
    dial: '262',
    lang: [
      'fr',
    ],
  },
  {
    id: '266',
    name: 'Gabon',
    code: 'GA',
    dial: '241',
    lang: [
      'fr',
    ],
  },
  {
    id: '270',
    name: 'Gambia',
    code: 'GM',
    dial: '220',
    lang: [
      'en',
    ],
  },
  {
    id: '268',
    name: 'Georgia',
    code: 'GE',
    dial: '995',
    lang: [
      'ka',
      'hy',
      'ab',
    ],
  },
  {
    id: '276',
    name: 'Germany',
    code: 'DE',
    dial: '49',
    lang: [
      'de',
    ],
  },
  {
    id: '288',
    name: 'Ghana',
    code: 'GH',
    dial: '233',
    lang: [
      'en',
    ],
  },
  {
    id: '292',
    name: 'Gibraltar',
    code: 'GI',
    dial: '350',
    lang: [
      'en',
      'es',
      'it',
    ],
  },
  {
    id: '300',
    name: 'Greece',
    code: 'GR',
    dial: '30',
    lang: [
      'el',
    ],
  },
  {
    id: '304',
    name: 'Greenland',
    code: 'GL',
    dial: '299',
    lang: [
      'kl',
      'da',
    ],
  },
  {
    id: '308',
    name: 'Grenada',
    code: 'GD',
    dial: '1473',
    lang: [
      'en',
      'fr',
    ],
  },
  {
    id: '312',
    name: 'Guadeloupe',
    code: 'GP',
    dial: '590',
    lang: [],
  },
  {
    id: '316',
    name: 'Guam',
    code: 'GU',
    dial: '1671',
    lang: [
      'en',
      'tl',
    ],
  },
  {
    id: '320',
    name: 'Guatemala',
    code: 'GT',
    dial: '502',
    lang: [
      'es',
    ],
  },
  {
    id: '831',
    name: 'Guernsey',
    code: 'GG',
    dial: '44',
    lang: [
      'en',
      'fr',
    ],
  },
  {
    id: '324',
    name: 'Guinea',
    code: 'GN',
    dial: '224',
    lang: [
      'fr',
    ],
  },
  {
    id: '624',
    name: 'Guinea-Bissau',
    code: 'GW',
    dial: '245',
    lang: [
      'pt',
    ],
  },
  {
    id: '328',
    name: 'Guyana',
    code: 'GY',
    dial: '592',
    lang: [
      'en',
    ],
  },
  {
    id: '332',
    name: 'Haiti',
    code: 'HT',
    dial: '509',
    lang: [
      'fr',
      'ht',
    ],
  },
  {
    id: '334',
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
    dial: '672',
    lang: [],
  },
  {
    id: '336',
    name: 'Holy See (Vatican City State)',
    code: 'VA',
    dial: '3906',
    lang: [
      'it',
      'la',
      'fr',
    ],
  },
  {
    id: '340',
    name: 'Honduras',
    code: 'HN',
    dial: '504',
    lang: [
      'es',
    ],
  },
  {
    id: '344',
    name: 'Hong Kong',
    code: 'HK',
    dial: '852',
    lang: [
      'zh',
      'en',
    ],
  },
  {
    id: '348',
    name: 'Hungary',
    code: 'HU',
    dial: '36',
    lang: [
      'hu',
      'en',
      'de',
    ],
  },
  {
    id: '352',
    name: 'Iceland',
    code: 'IS',
    dial: '354',
    lang: [
      'is',
      'en',
    ],
  },
  {
    id: '356',
    name: 'India',
    code: 'IN',
    dial: '91',
    lang: [
      'hi',
      'bn',
      'te',
    ],
  },
  {
    id: '360',
    name: 'Indonesia',
    code: 'ID',
    dial: '62',
    lang: [
      'id',
      'en',
      'nl',
    ],
  },
  {
    id: '364',
    name: 'Iran',
    code: 'IR',
    dial: '98',
    lang: [
      'fa',
      'az',
      'ku',
    ],
  },
  {
    id: '368',
    name: 'Iraq',
    code: 'IQ',
    dial: '964',
    lang: [
      'ar',
      'ku',
      'tk',
    ],
  },
  {
    id: '372',
    name: 'Ireland',
    code: 'IE',
    dial: '353',
    lang: [
      'en',
      'ga',
    ],
  },
  {
    id: '833',
    name: 'Isle of Man',
    code: 'IM',
    dial: '44',
    lang: [
      'en',
    ],
  },
  {
    id: '376',
    name: 'Israel',
    code: 'IL',
    dial: '972',
    lang: [
      'he',
      'ar',
      'en',
    ],
  },
  {
    id: '380',
    name: 'Italy',
    code: 'IT',
    dial: '39',
    lang: [
      'it',
      'de',
      'fr',
    ],
  },
  {
    id: '388',
    name: 'Jamaica',
    code: 'JM',
    dial: '1876',
    lang: [
      'en',
    ],
  },
  {
    id: '392',
    name: 'Japan',
    code: 'JP',
    dial: '81',
    lang: [
      'ja',
    ],
  },
  {
    id: '832',
    name: 'Jersey',
    code: 'JE',
    dial: '44',
    lang: [
      'en',
      'pt',
    ],
  },
  {
    id: '400',
    name: 'Jordan',
    code: 'JO',
    dial: '962',
    lang: [
      'ar',
      'en',
    ],
  },
  {
    id: '398',
    name: 'Kazakhstan',
    code: 'KZ',
    dial: '7',
    lang: [
      'kk',
    ],
  },
  {
    id: '404',
    name: 'Kenya',
    code: 'KE',
    dial: '254',
    lang: [
      'en',
      'sw',
    ],
  },
  {
    id: '296',
    name: 'Kiribati',
    code: 'KI',
    dial: '686',
    lang: [
      'en',
    ],
  },
  {
    id: '408',
    name: 'Democratic Peoples Republic of Korea',
    code: 'KP',
    dial: '850',
    lang: [
      'ko',
    ],
  },
  {
    id: '410',
    name: 'Republic of Korea',
    code: 'KR',
    dial: '82',
    lang: [
      'ko',
      'en',
    ],
  },
  {
    id: '414',
    name: 'Kuwait',
    code: 'KW',
    dial: '965',
    lang: [
      'ar',
      'en',
    ],
  },
  {
    id: '417',
    name: 'Kyrgyzstan',
    code: 'KG',
    dial: '996',
    lang: [
      'ky',
      'uz',
    ],
  },
  {
    id: '418',
    name: 'Lao',
    code: 'LA',
    dial: '856',
    lang: [
      'lo',
      'fr',
    ],
  },
  {
    id: '428',
    name: 'Latvia',
    code: 'LV',
    dial: '371',
    lang: [
      'lv',
      'pl',
      'uk',
    ],
  },
  {
    id: '422',
    name: 'Lebanon',
    code: 'LB',
    dial: '961',
    lang: [
      'ar',
      'fr',
    ],
  },
  {
    id: '426',
    name: 'Lesotho',
    code: 'LS',
    dial: '266',
    lang: [
      'st',
      'en',
    ],
  },
  {
    id: '430',
    name: 'Liberia',
    code: 'LR',
    dial: '231',
    lang: [
      'en',
    ],
  },
  {
    id: '434',
    name: 'Libya',
    code: 'LY',
    dial: '218',
    lang: [
      'ar',
      'it',
      'en',
    ],
  },
  {
    id: '438',
    name: 'Liechtenstein',
    code: 'LI',
    dial: '423',
    lang: [
      'de',
      'it',
    ],
  },
  {
    id: '440',
    name: 'Lithuania',
    code: 'LT',
    dial: '370',
    lang: [
      'lt',
      'pl',
    ],
  },
  {
    id: '442',
    name: 'Luxembourg',
    code: 'LU',
    dial: '352',
    lang: [
      'lb',
      'fr',
      'de',
    ],
  },
  {
    id: '446',
    name: 'Macao',
    code: 'MO',
    dial: '853',
    lang: [
      'zh',
      'tl',
      'en',
    ],
  },
  {
    id: '807',
    name: 'Macedonia',
    code: 'MK',
    dial: '389',
    lang: [
      'mk',
      'sq',
    ],
  },
  {
    id: '450',
    name: 'Madagascar',
    code: 'MG',
    dial: '261',
    lang: [
      'fr',
      'mg',
      'en',
    ],
  },
  {
    id: '454',
    name: 'Malawi',
    code: 'MW',
    dial: '265',
    lang: [
      'en',
      'ny',
    ],
  },
  {
    id: '458',
    name: 'Malaysia',
    code: 'MY',
    dial: '60',
    lang: [
      'ms',
      'en',
      'zh',
    ],
  },
  {
    id: '462',
    name: 'Maldives',
    code: 'MV',
    dial: '960',
    lang: [
      'dv',
      'en',
    ],
  },
  {
    id: '466',
    name: 'Mali',
    code: 'ML',
    dial: '223',
    lang: [
      'fr',
      'bm',
    ],
  },
  {
    id: '470',
    name: 'Malta',
    code: 'MT',
    dial: '356',
    lang: [
      'mt',
      'en',
    ],
  },
  {
    id: '584',
    name: 'Marshall Islands',
    code: 'MH',
    dial: '692',
    lang: [
      'mh',
      'en',
    ],
  },
  {
    id: '474',
    name: 'Martinique',
    code: 'MQ',
    dial: '596',
    lang: [],
  },
  {
    id: '478',
    name: 'Mauritania',
    code: 'MR',
    dial: '222',
    lang: [
      'ar',
      'ff',
    ],
  },
  {
    id: '480',
    name: 'Mauritius',
    code: 'MU',
    dial: '230',
    lang: [
      'bh',
      'en',
      'fr',
    ],
  },
  {
    id: '175',
    name: 'Mayotte',
    code: 'YT',
    dial: '262',
    lang: [],
  },
  {
    id: '484',
    name: 'Mexico',
    code: 'MX',
    dial: '52',
    lang: [
      'es',
    ],
  },
  {
    id: '583',
    name: 'Micronesia',
    code: 'FM',
    dial: '691',
    lang: [],
  },
  {
    id: '498',
    name: 'Moldova',
    code: 'MD',
    dial: '373',
    lang: [
      'ro',
      'tr',
      'uk',
    ],
  },
  {
    id: '492',
    name: 'Monaco',
    code: 'MC',
    dial: '377',
    lang: [
      'fr',
      'en',
      'it',
    ],
  },
  {
    id: '496',
    name: 'Mongolia',
    code: 'MN',
    dial: '976',
    lang: [
      'mn',
    ],
  },
  {
    id: '499',
    name: 'Montenegro',
    code: 'ME',
    dial: '382',
    lang: [
      'sr',
      'bs',
      'sq',
    ],
  },
  {
    id: '500',
    name: 'Montserrat',
    code: 'MS',
    dial: '1664',
    lang: [
      'en',
    ],
  },
  {
    id: '504',
    name: 'Morocco',
    code: 'MA',
    dial: '212',
    lang: [
      'ar',
      'fr',
    ],
  },
  {
    id: '508',
    name: 'Mozambique',
    code: 'MZ',
    dial: '258',
    lang: [
      'pt',
    ],
  },
  {
    id: '104',
    name: 'Myanmar',
    code: 'MM',
    dial: '95',
    lang: [],
  },
  {
    id: '516',
    name: 'Namibia',
    code: 'NA',
    dial: '264',
    lang: [
      'af',
    ],
  },
  {
    id: '520',
    name: 'Nauru',
    code: 'NR',
    dial: '674',
    lang: [
      'na',
      'en',
    ],
  },
  {
    id: '524',
    name: 'Nepal',
    code: 'NP',
    dial: '977',
    lang: [
      'ne',
      'bh',
    ],
  },
  {
    id: '528',
    name: 'Netherlands',
    code: 'NL',
    dial: '31',
    lang: [
      'nl',
      'fy',
    ],
  },
  {
    id: '540',
    name: 'New Caledonia',
    code: 'NC',
    dial: '687',
    lang: [
      'fr',
    ],
  },
  {
    id: '554',
    name: 'New Zealand',
    code: 'NZ',
    dial: '64',
    lang: [
      'en',
      'mi',
    ],
  },
  {
    id: '558',
    name: 'Nicaragua',
    code: 'NI',
    dial: '505',
    lang: [
      'es',
    ],
  },
  {
    id: '562',
    name: 'Niger',
    code: 'NE',
    dial: '227',
    lang: [
      'fr',
    ],
  },
  {
    id: '566',
    name: 'Nigeria',
    code: 'NG',
    dial: '234',
    lang: [
      'en',
      'ha',
    ],
  },
  {
    id: '570',
    name: 'Niue',
    code: 'NU',
    dial: '683',
    lang: [
      'en',
    ],
  },
  {
    id: '574',
    name: 'Norfolk Island',
    code: 'NF',
    dial: '672',
    lang: [
      'en',
    ],
  },
  {
    id: '580',
    name: 'Northern Mariana Islands',
    code: 'MP',
    dial: '1670',
    lang: [
      'ch',
      'en',
    ],
  },
  {
    id: '578',
    name: 'Norway',
    code: 'NO',
    dial: '47',
    lang: [
      'nb',
      'nn',
      'se',
    ],
  },
  {
    id: '512',
    name: 'Oman',
    code: 'OM',
    dial: '968',
    lang: [
      'ar',
      'en',
    ],
  },
  {
    id: '586',
    name: 'Pakistan',
    code: 'PK',
    dial: '92',
    lang: [
      'pa',
      'sd',
    ],
  },
  {
    id: '585',
    name: 'Palau',
    code: 'PW',
    dial: '680',
    lang: [
      'en',
    ],
  },
  {
    id: '275',
    name: 'Palestine',
    code: 'PS',
    dial: '970',
    lang: [],
  },
  {
    id: '591',
    name: 'Panama',
    code: 'PA',
    dial: '507',
    lang: [
      'es',
    ],
  },
  {
    id: '598',
    name: 'Papua New Guinea',
    code: 'PG',
    dial: '675',
    lang: [
      'en',
    ],
  },
  {
    id: '600',
    name: 'Paraguay',
    code: 'PY',
    dial: '595',
    lang: [
      'es',
    ],
  },
  {
    id: '604',
    name: 'Peru',
    code: 'PE',
    dial: '51',
    lang: [
      'es',
      'qu',
    ],
  },
  {
    id: '608',
    name: 'Philippines',
    code: 'PH',
    dial: '63',
    lang: [
      'tl',
      'en',
    ],
  },
  {
    id: '612',
    name: 'Pitcairn',
    code: 'PN',
    dial: '870',
    lang: [
      'en',
    ],
  },
  {
    id: '616',
    name: 'Poland',
    code: 'PL',
    dial: '48',
    lang: [
      'pl',
    ],
  },
  {
    id: '620',
    name: 'Portugal',
    code: 'PT',
    dial: '351',
    lang: [
      'pt',
    ],
  },
  {
    id: '630',
    name: 'Puerto Rico',
    code: 'PR',
    dial: '1',
    lang: [
      'es',
      'en',
    ],
  },
  {
    id: '634',
    name: 'Qatar',
    code: 'QA',
    dial: '974',
    lang: [
      'ar',
      'en',
    ],
  },
  {
    id: '642',
    name: 'Romania',
    code: 'RO',
    dial: '40',
    lang: [
      'ro',
      'hu',
    ],
  },
  {
    id: '643',
    name: 'Russian Federation',
    code: 'RU',
    dial: '7',
    lang: [
      'ru',
      'tt',
      'ce',
    ],
  },
  {
    id: '646',
    name: 'Rwanda',
    code: 'RW',
    dial: '250',
    lang: [
      'rw',
      'fr',
    ],
  },
  {
    id: '638',
    name: 'Reunion',
    code: 'RE',
    dial: '262',
    lang: [],
  },
  {
    id: '652',
    name: 'Saint Bartholemy',
    code: 'BL',
    dial: '590',
    lang: [],
  },
  {
    id: '659',
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    dial: '1869',
    lang: [
      'en',
    ],
  },
  {
    id: '662',
    name: 'Saint Lucia',
    code: 'LC',
    dial: '1758',
    lang: [
      'en',
      'fr',
    ],
  },
  {
    id: '663',
    name: 'Saint Martin (French part)',
    code: 'MF',
    dial: '590',
    lang: [
      'fr',
    ],
  },
  {
    id: '666',
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    dial: '508',
    lang: [
      'fr',
    ],
  },
  {
    id: '670',
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    dial: '1784',
    lang: [
      'en',
      'fr',
    ],
  },
  {
    id: '882',
    name: 'Samoa',
    code: 'WS',
    dial: '685',
    lang: [
      'sm',
      'en',
    ],
  },
  {
    id: '674',
    name: 'San Marino',
    code: 'SM',
    dial: '378',
    lang: [
      'it',
    ],
  },
  {
    id: '678',
    name: 'Sao Tome and Principe',
    code: 'ST',
    dial: '239',
    lang: [
      'pt',
    ],
  },
  {
    id: '682',
    name: 'Saudi Arabia',
    code: 'SA',
    dial: '966',
    lang: [
      'ar',
    ],
  },
  {
    id: '686',
    name: 'Senegal',
    code: 'SN',
    dial: '221',
    lang: [
      'fr',
    ],
  },
  {
    id: '688',
    name: 'Serbia',
    code: 'RS',
    dial: '381',
    lang: [
      'sr',
      'hu',
    ],
  },
  {
    id: '690',
    name: 'Seychelles',
    code: 'SC',
    dial: '248',
    lang: [
      'en',
      'fr',
    ],
  },
  {
    id: '694',
    name: 'Sierra Leone',
    code: 'SL',
    dial: '232',
    lang: [
      'en',
    ],
  },
  {
    id: '702',
    name: 'Singapore',
    code: 'SG',
    dial: '65',
    lang: [
      'zh',
      'en',
      'ms',
    ],
  },
  {
    id: '534',
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
    dial: '1721',
    lang: [],
  },
  {
    id: '703',
    name: 'Slovakia',
    code: 'SK',
    dial: '421',
    lang: [
      'sk',
      'hu',
    ],
  },
  {
    id: '705',
    name: 'Slovenia',
    code: 'SI',
    dial: '386',
    lang: [
      'sl',
      'it',
      'hu',
    ],
  },
  {
    id: '090',
    name: 'Solomon Islands',
    code: 'SB',
    dial: '677',
    lang: [
      'en',
    ],
  },
  {
    id: '706',
    name: 'Somalia',
    code: 'SO',
    dial: '252',
    lang: [
      'so',
      'ar',
    ],
  },
  {
    id: '710',
    name: 'South Africa',
    code: 'ZA',
    dial: '27',
    lang: [
      'zu',
      'xh',
      'af',
    ],
  },
  {
    id: '239',
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    dial: '500',
    lang: [],
  },
  {
    id: '728',
    name: 'South Sudan',
    code: 'SS',
    dial: '211',
    lang: [
      'en',
      'ar',
    ],
  },
  {
    id: '724',
    name: 'Spain',
    code: 'ES',
    dial: '34',
    lang: [
      'es',
    ],
  },
  {
    id: '144',
    name: 'Sri Lanka',
    code: 'LK',
    dial: '94',
    lang: [
      'si',
      'ta',
    ],
  },
  {
    id: '729',
    name: 'Sudan',
    code: 'SD',
    dial: '249',
    lang: [
      'ar',
      'en',
    ],
  },
  {
    id: '740',
    name: 'Suriname',
    code: 'SR',
    dial: '597',
    lang: [
      'nl',
      'en',
    ],
  },
  {
    id: '744',
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    dial: '47',
    lang: [
      'no',
    ],
  },
  {
    id: '748',
    name: 'Swaziland',
    code: 'SZ',
    dial: '268',
    lang: [
      'en',
    ],
  },
  {
    id: '752',
    name: 'Sweden',
    code: 'SE',
    dial: '46',
    lang: [
      'sv',
      'fi',
    ],
  },
  {
    id: '756',
    name: 'Switzerland',
    code: 'CH',
    dial: '41',
    lang: [],
  },
  {
    id: '760',
    name: 'Syrian Arab Republic',
    code: 'SY',
    dial: '963',
    lang: [
      'ar',
      'ku',
    ],
  },
  {
    id: '158',
    name: 'Taiwan',
    code: 'TW',
    dial: '886',
    lang: [
      'zh',
    ],
  },
  {
    id: '762',
    name: 'Tajikistan',
    code: 'TJ',
    dial: '992',
    lang: [
      'tg',
    ],
  },
  {
    id: '834',
    name: 'Tanzania',
    code: 'TZ',
    dial: '255',
    lang: [
      'sw',
      'en',
    ],
  },
  {
    id: '764',
    name: 'Thailand',
    code: 'TH',
    dial: '66',
    lang: [
      'th',
      'en',
    ],
  },
  {
    id: '626',
    name: 'Timor-Leste',
    code: 'TL',
    dial: '670',
    lang: [
      'pt',
    ],
  },
  {
    id: '768',
    name: 'Togo',
    code: 'TG',
    dial: '228',
    lang: [
      'fr',
    ],
  },
  {
    id: '772',
    name: 'Tokelau',
    code: 'TK',
    dial: '690',
    lang: [
      'en',
    ],
  },
  {
    id: '776',
    name: 'Tonga',
    code: 'TO',
    dial: '676',
    lang: [
      'en',
      'to',
    ],
  },
  {
    id: '780',
    name: 'Trinidad and Tobago',
    code: 'TT',
    dial: '1868',
    lang: [
      'en',
    ],
  },
  {
    id: '788',
    name: 'Tunisia',
    code: 'TN',
    dial: '216',
    lang: [
      'ar',
      'fr',
    ],
  },
  {
    id: '792',
    name: 'Turkey',
    code: 'TR',
    dial: '90',
    lang: [
      'tr',
      'ku',
    ],
  },
  {
    id: '795',
    name: 'Turkmenistan',
    code: 'TM',
    dial: '993',
    lang: [
      'tk',
      'uz',
    ],
  },
  {
    id: '796',
    name: 'Turks and Caicos Islands',
    code: 'TC',
    dial: '1649',
    lang: [
      'en',
    ],
  },
  {
    id: '798',
    name: 'Tuvalu',
    code: 'TV',
    dial: '688',
    lang: [
      'en',
    ],
  },
  {
    id: '800',
    name: 'Uganda',
    code: 'UG',
    dial: '256',
    lang: [
      'en',
    ],
  },
  {
    id: '804',
    name: 'Ukraine',
    code: 'UA',
    dial: '380',
    lang: [
      'uk',
      'ru',
    ],
  },
  {
    id: '784',
    name: 'United Arab Emirates',
    code: 'AE',
    dial: '971',
    lang: [
      'ar',
    ],
  },
  {
    id: '826',
    name: 'United Kingdom',
    code: 'GB',
    dial: '44',
    lang: [
      'en',
    ],
  },
  {
    id: '840',
    name: 'United States',
    code: 'US',
    dial: '1',
    lang: [
      'en',
      'es',
    ],
  },
  {
    id: '858',
    name: 'Uruguay',
    code: 'UY',
    dial: '598',
    lang: [
      'es',
    ],
  },
  {
    id: '860',
    name: 'Uzbekistan',
    code: 'UZ',
    dial: '998',
    lang: [
      'uz',
      'tg',
    ],
  },
  {
    id: '548',
    name: 'Vanuatu',
    code: 'VU',
    dial: '678',
    lang: [
      'bi',
      'en',
      'fr',
    ],
  },
  {
    id: '862',
    name: 'Venezuela',
    code: 'VE',
    dial: '58',
    lang: [
      'es',
    ],
  },
  {
    id: '704',
    name: 'Viet Nam',
    code: 'VN',
    dial: '84',
    lang: [
      'vi',
      'en',
    ],
  },
  {
    id: '092',
    name: 'Virgin Islands, British',
    code: 'VG',
    dial: '1284',
    lang: [
      'en',
    ],
  },
  {
    id: '850',
    name: 'Virgin Islands, U.S.',
    code: 'VI',
    dial: '1340',
    lang: [
      'en',
      'es',
    ],
  },
  {
    id: '876',
    name: 'Wallis and Futuna',
    code: 'WF',
    dial: '681',
    lang: [
      'fr',
    ],
  },
  {
    id: '732',
    name: 'Western Sahara',
    code: 'EH',
    dial: '212',
    lang: [],
  },
  {
    id: '887',
    name: 'Yemen',
    code: 'YE',
    dial: '967',
    lang: [
      'ar',
    ],
  },
  {
    id: '894',
    name: 'Zambia',
    code: 'ZM',
    dial: '260',
    lang: [
      'ny',
      'to',
      'en',
    ],
  },
  {
    id: '716',
    name: 'Zimbabwe',
    code: 'ZW',
    dial: '263',
    lang: [
      'sn',
      'nd',
      'en',
    ],
  },
];

export const AFRICA_LIST = [
  'NG', 'ET', 'CD', 'EG', 'ZA', 'TZ', 'KE', 'UG', 'DZ', 'SS', 'MA', 'MZ', 'GH', 'AO', 'SO', 'CI', 'MG', 'CM', 'BF',
  'NE', 'MW', 'ZM', 'ML', 'SN', 'ZW', 'TD', 'TN', 'GN', 'RW', 'BJ', 'BI', 'SS', 'ER', 'SL', 'TG', 'LY', 'CF', 'MR',
  'CD', 'LR', 'NA', 'BW', 'LS', 'GM', 'GA', 'GW', 'MU', 'GQ', 'SZ', 'DJ', 'KM', 'EH', 'CV', 'YT', 'ST', 'SC', 'RE',
];
